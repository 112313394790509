<template>
  <div>
    <router-link
      class="btn btn-primary mr-2"
      :to="{
        path: '/credit/bank/transfer/request/add',
        query: {
          userId: $route.params.userId,
        },
      }"
      v-show="canBuildDeposit"
      >สร้างยอดฝาก</router-link
    >
    <button
      class="btn mr-2"
      :class="{
        'btn-success': intervalFunc,
        'btn-outline-success': !intervalFunc,
      }"
      @click="toggleInterval"
    >
      รีเฟรช
    </button>
    <button
      class="btn"
      :class="{
        'btn-warning': columnFilterValue.status == 'PENDING_APPROVE_WITHDRAW',
        'btn-outline-warning':
          columnFilterValue.status != 'PENDING_APPROVE_WITHDRAW',
      }"
      @click="setStatus('PENDING_APPROVE_WITHDRAW')"
    >
      รอการอนุมัติ
    </button>
    <CDataTable
      :items="items"
      :fields="fields"
      :column-filter="{ external: true }"
      :items-per-page-select="{ label: 'จำนวนแถว' }"
      :table-filter-value.sync="tableFilterValue"
      :column-filter-value.sync="columnFilterValue"
      :sorter-value.sync="sorterValue"
      :items-per-page.sync="itemsPerPage"
      :loading="tableLoading"
      :noItemsView="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }"
      v-on:pagination-change="paginationChange"
      v-on:update:column-filter-value="columnFilter"
      hover
      sorter
      pagination
      dark
      border
      size="sm"
      addTableClasses="w-100 text-wrap"
    >
      <template #userId="{ item }">
        <td>
          <router-link :to="`/members/${item.userId}`">{{
            item.userId
          }}</router-link>
        </td>
      </template>
      <template #action="{ item }">
        <td>
          <div
            class="d-flex"
            v-if="
              (item.status === 'PENDING_APPROVE_WITHDRAW' ||
                item.status === 'INVESTIGATE' ||
                item.status === 'TIMEOUT') &&
              item.code == 'WITHDRAW'
            "
          >
            <button
              class="btn btn-primary mr-1"
              @click="update(item, 'PENDING')"
              v-show="canApprove"
            >
              อนุมัติ
            </button>
            <button
              class="btn btn-danger mr-1"
              @click="update(item, 'REJECT')"
              v-show="canApprove"
            >
              ไม่อนุมัติ
            </button>
            <button
              class="btn btn-warning mr-1"
              @click="update(item, 'INVESTIGATE')"
            >
              กำลังตรวจสอบ
            </button>
            <button class="btn btn-success" @click="update(item, 'SUCCESS')">
              โอนแล้ว
            </button>
          </div>
          <div class="d-flex">
            <button
              v-if="item.status === 'PENDING_APPROVE_DEPOSIT'"
              class="btn btn-primary mr-1"
              @click="approveDepositByAgent(item, 'SUCCESS')"
              v-show="canApprove"
            >
              อนุมัติ
            </button>
            <button
              v-if="item.status === 'PENDING_APPROVE_DEPOSIT'"
              class="btn btn-danger mr-1"
              @click="approveDepositByAgent(item, 'REJECT')"
              v-show="canApprove"
            >
              ไม่อนุมัติ
            </button>
            <button
              v-if="
                !['SUCCESS', 'PENDING_APPROVE_DEPOSIT', 'REJECT'].includes(
                  item.status
                ) && item.code == 'DEPOSIT'
              "
              class="btn btn-primary mr-1"
              @click="openModalApproveDeposit(item)"
              v-show="canBindDeposit"
            >
              ผูกยอด
            </button>
          </div>
        </td>
      </template>
    </CDataTable>
    <CPagination
      :active-page="activePage"
      :double-arrows="false"
      :pages="totalPage"
      :dots="false"
      v-on:update:activePage="updateActivePage"
    />
    <b-modal
      id="modal-approve-deposit"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      title="ผูกยอด"
    >
      <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="approveDeposit"
        novalidate
      >
        <div class="form-group">
          <label for="id">#ID</label>
          <input
            type="text"
            class="form-control"
            id="id"
            v-model="approveDepositData.id"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="id">#สมาชิก</label>
          <input
            type="text"
            class="form-control"
            id="id"
            v-model="approveDepositData.userId"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="id">จำนวน</label>
          <input
            type="text"
            class="form-control"
            id="id"
            v-model="approveDepositData.amount"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="line">ชื่อ line ลูกค้า</label>
          <ValidationProvider :rules="`required`" v-slot="v">
            <input
              type="text"
              class="form-control"
              id="line"
              placeholder="ชื่อ line ลูกค้า"
              v-model="approveDepositData.line"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label for="bankStatementId">#เงินเข้าออก</label>
          <ValidationProvider :rules="`required|numeric`" v-slot="v">
            <input
              type="text"
              class="form-control"
              id="bankStatementId"
              placeholder="#เงินเข้าออก"
              v-model.number="approveDepositData.bankStatementId"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </ValidationObserver>
      <template #modal-footer>
        <button class="btn btn-danger mr-2" @click="hideModalApproveDeposit">
          ยกเลิก
        </button>
        <button class="btn btn-primary" @click="approveDeposit">ผูกยอด</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
          sorter: false,
        },
        {
          key: "userId",
          label: "#สมาชิก",
          sorter: false,
          filter: this.$route.params.userId ? false : true,
        },
        {
          key: "paymentPlatformId",
          label: "orderId",
          sorter: false,
        },
        {
          key: "userBankAccountId",
          label: "#บัญชีธนาคาร",
          sorter: false,
        },
        {
          key: "userApproveId",
          label: "#คนอนุมัติ",
          sorter: false,
        },
        {
          key: "bankCode",
          label: "ธนาคารฝาก",
          sorter: false,
        },
        {
          key: "userBankCode",
          label: "ธนาคารถอน",
          sorter: false,
        },
        {
          key: "accountNumber",
          label: "เลขบัญชีถอน",
          sorter: false,
        },
        {
          key: "amount",
          label: "จำนวน",
          sorter: false,
        },
        {
          key: "code",
          label: "ฝากถอน",
          filter: false,
          sorter: false,
        },
        {
          key: "createdAt",
          label: "เวลาสร้าง",
          filter: false,
          sorter: false,
        },
        {
          key: "updatedAt",
          label: "อัพเดตล่าสุด",
          filter: false,
          sorter: false,
        },
        {
          key: "statusTH",
          label: "สถานะ",
          filter: false,
          sorter: false,
        },
        {
          key: "action",
          label: "อนุมัติ/ไม่อนุมัติ",
          filter: false,
          sorter: false,
        },
      ],
      items: [],
      tableFilterValue: "",
      columnFilterValue: {
        status: null,
        userId: null,
      },
      sorterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: true,
      isLoading: true,
      intervalFunc: null,
      approveDepositData: {},
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canApprove() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "bank_transfer_request_approve"
      )
    },
    canBindDeposit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "bank_transfer_request_bind_deposit"
      )
    },
    canBuildDeposit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "bank_transfer_request_build_deposit"
      )
    },
    code() {
      return this.$route.query.code
    },
  },
  watch: {
    code() {
      this.$store.dispatch("auth/forceReload")
    },
  },
  methods: {
    hideModalApproveDeposit() {
      this.$bvModal.hide("modal-approve-deposit")
    },
    openModalApproveDeposit(item) {
      this.approveDepositData = item

      this.$bvModal.show("modal-approve-deposit")
    },
    setStatus(status) {
      this.columnFilterValue.status =
        this.columnFilterValue.status == null ? status : null

      this.loadData()
    },
    toggleInterval() {
      if (this.intervalFunc) {
        clearInterval(this.intervalFunc)
        this.intervalFunc = null
      } else {
        this.intervalFunc = setInterval(() => {
          this.loadData()
        }, 20 * 1000)
      }
    },
    async approveDepositByAgent(item, status) {
      let confirm = await this.swal.confirm(
        status === "SUCCESS" ? "อนุมัติยอดฝาก" : "ไม่อนุมัติยอดฝาก",
        status === "SUCCESS"
          ? "ท่านต้องการอนุมัติยอดฝากใช่หรือไม่"
          : "ท่านต้องการไม่อนุมัติยอดฝากใช่หรือไม่"
      )
      if (!confirm) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "credit/deposit/approve/by-agent",
          data: {
            userTransferRequestId: item.id,
            status: status,
          },
        })

        await this.$swal({
          icon: "success",
          title: "สำเร็จ",
        })

        await this.loadData()
      } catch (err) {
        console.log(err)
        this.swal.catchError(err)
      }
    },
    async approveDeposit() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "credit/bank/transfer/request/deposit/approve",
          data: {
            userTransferRequestId: this.approveDepositData.id,
            bankStatementId: parseInt(this.approveDepositData.bankStatementId),
            line: this.approveDepositData.line,
            status: "SUCCESS",
          },
        })

        this.hideModalApproveDeposit()

        await this.$swal({
          icon: "success",
          title: "สำเร็จ",
        })

        await this.loadData()
      } catch (err) {
        console.log(err)
        this.swal.swalError(err.response.data.description)
      }
    },
    async update(item, status) {
      if (item.status == status) {
        return
      }

      if (status == "TIMEOUT") {
        let res = await this.$swal({
          title: `รายการนี้ได้มีการทำรายการไปแล้วใน payment gateway กรุณาตรวจสอบสถานะก่อนทำการอนุมัติใหม่อีกครั้ง`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        })
        if (!res.isConfirmed) {
          return
        }
      }

      if (status == "REJECT") {
        let res = await this.$swal({
          title: `เมื่อไม่อนุมัติถอนเงิน ทางระบบจะทำการคืนเครดิตให้ลูกค้า ท่านต้องการทำรายการใช่หรือไม่`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        })
        if (!res.isConfirmed) {
          return
        }
      }

      if (item.withdrawStatus === "SHOW_ALERT_BEFORE_APPROVE") {
        let res = await this.$swal({
          text: `รายการถอนนี้เคยมีการโอนไปแล้วแต่ไม่สำเร็จ กรุณาตรวจสอบก่อนว่าโอนสำเร็จหรือไม่`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ตรวจสอบแล้ว",
          cancelButtonText: "ยกเลิก",
        })
        if (!res.isConfirmed) {
          return
        }
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "credit/bank/transfer/request",
          data: {
            id: item.id,
            status: status,
          },
        })

        await this.$swal({
          icon: "success",
          title: "สำเร็จ",
        })

        this.loadData()
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
        })
      }
    },
    getClassesCode(code) {
      let classes = ""
      if (code == "WITHDRAW") {
        classes = "text-negative"
      } else if (code == "DEPOSIT") {
        classes = "text-positive"
      }
      return classes
    },
    getClassesStatus(status) {
      if (status == "SUCCESS") return "text-positive"

      return "text-negative"
    },
    paginationChange(val) {
      this.itemsPerPage = val
      this.loadData()
    },
    updateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.items &&
        this.items.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val
      this.loadData()
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.loadData()
      }, 500)
    },
    async loadData() {
      this.tableLoading = true

      if (this.$route.params.userId) {
        this.columnFilterValue.userId = "=" + this.$route.params.userId
      }

      if (this.code) {
        this.columnFilterValue.code = "=" + this.code

        if (this.code === "DEPOSIT") {
          this.fields = this.fields.filter(
            (item) =>
              item.key !== "userBankCode" && item.key !== "accountNumber"
          )
        } else {
          this.fields = this.fields.filter((item) => item.key !== "bankCode")
        }
      }

      try {
        const res = await this.axios({
          method: "get",
          url: "credit/bank/transfer/request",
          params: {
            ...this.columnFilterValue,
            page: this.activePage,
            pageSize: this.itemsPerPage,
          },
        })

        let data = res.data.data
        data.data &&
          data.data.forEach((item) => {
            item._cellClasses = {
              code: this.getClassesCode(item.code),
              statusTH: this.getClassesStatus(item.status),
            }
            item.userApproveId =
              item.userApproveId == 0 ? "" : item.userApproveId
            item.dateTime = `${item.depositDate} ${item.depositTime}`
            item.updatedAt = this.$date(item.updatedAt).format(
              "DD/MM/YYYY HH:mm:ss"
            )
            item.createdAt = this.$date(item.createdAt).format(
              "DD/MM/YYYY HH:mm:ss"
            )
            item.amount = this.util.formatMoney(item.amount)
            item.amountDeposit = this.util.formatMoney(item.amountDeposit)
          })
        this.items = data.data
        this.tableLoading = false
        this.isLoading = false
      } catch (err) {
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
