<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['รายการแทง', 'ตั้งค่าอัตราจ่าย']" />
    <round-detail />
    <div class="alert alert-info" role="alert">
      ตั้งค่าอัตราจ่ายโดยระบุตัวเลข เช่น 500
      หรือถ้าต้องการตั้งเป็นเปอร์เซนต์ให้ใส่เครื่องหมาย % ต่อท้าย เช่น 50%
      เป็นต้น
    </div>
    <div class="mb-3">
      <h4>
        <i class="fa fa-angle-right text-primary" />
        ตั้งค่าอัตราจ่ายเป็นรายตัว (เฉพาะงวด)
      </h4>
      ใส่อัตราจ่ายต่อหมายเลข
    </div>
    <loading v-show="isLoading" />
    <div class="container-fluid" v-show="!isLoading">
      <div class="row">
        <div
          class="col-12 col-sm-6 col-lg-4 col-xl-3 px-2 m-0"
          v-for="cate in categories"
          :key="cate.id"
        >
          <table
            class="table table-sm table-bordered table-hover table-dark text-center"
          >
            <thead>
              <tr>
                <th style="width: 38px">
                  <input
                    type="checkbox"
                    v-model="cate.checked"
                    @change="toggleCateChecked(cate)"
                    :disabled="!canEdit"
                  />
                </th>
                <th style="width: calc(100% - 38px)">{{ cate.name }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="d-flex flex-column">
                    <input
                      :id="cate.id + 'reverse'"
                      type="checkbox"
                      v-model="cate.reverse"
                      class="mx-auto"
                      :disabled="!canEdit"
                    />
                    <label :for="cate.id + 'reverse'">กลับ</label>
                  </div>
                </td>

                <td>
                  <ValidationObserver
                    :ref="'observer-create-' + cate.id"
                    tag="form"
                    class=""
                    @submit.prevent="addBetLimit(cate)"
                    novalidate
                    v-slot="v"
                  >
                    <div class="w-100 d-flex">
                      <ValidationProvider
                        rules="required|numeric"
                        tag="div"
                        class="mx-0"
                        name="number"
                      >
                        <input
                          type="text"
                          class="form-control"
                          style="min-width: 53px"
                          :maxlength="cate.numberLength"
                          @keypress="$root.onlyNumber"
                          v-model="cate.number"
                          placeholder="เลข"
                          :readonly="!canEdit"
                        />
                      </ValidationProvider>
                      <span class="m-1 my-auto">=</span>
                      <ValidationProvider
                        rules="required"
                        tag="div"
                        class="mx-0"
                        name="payRate"
                      >
                        <input
                          type="text"
                          class="form-control mr-1"
                          style="min-width: 53px"
                          v-model="cate.payRate"
                          placeholder="อัตราจ่าย"
                          @change="setPayRate(cate)"
                          :readonly="!canEdit"
                        />
                      </ValidationProvider>
                      <button class="btn btn-primary ml-1" v-show="canEdit">
                        เพิ่ม
                      </button>
                    </div>
                    <div class="w-100 text-left">
                      <span
                        v-if="v.errors.number && v.errors.number.length"
                        class="text-danger"
                      >
                        เลข {{ v.errors.number[0] }}
                      </span>
                    </div>
                    <div class="w-100 text-left">
                      <span
                        v-if="v.errors.payRate && v.errors.payRate.length"
                        class="text-danger"
                      >
                        อัตราจ่าย {{ v.errors.payRate[0] }}
                      </span>
                    </div>
                  </ValidationObserver>
                </td>
              </tr>
              <tr v-if="filteredBetLimitsByCategory(cate).length == 0">
                <td colspan="2">ไม่มีข้อมูล</td>
              </tr>
              <tr
                v-for="item in filteredBetLimitsByCategory(cate)"
                :key="item.id"
              >
                <td>
                  <input
                    type="checkbox"
                    v-model="item.checked"
                    :disabled="!canEdit"
                  />
                </td>
                <td>
                  {{ item.number }} = {{ item.payRate
                  }}{{ item.isPercent ? "%" : "" }}
                </td>
              </tr>
              <tr v-if="filteredBetLimitsByCategory(cate).length != 0">
                <td colspan="2">
                  <ValidationObserver
                    :ref="'observer-edit-' + cate.id"
                    tag="form"
                    class=""
                    @submit.prevent="editBetLimits(cate)"
                    novalidate
                    v-slot="v"
                  >
                    <div class="d-flex justify-content-center">
                      <ValidationProvider
                        rules="required"
                        tag="div"
                        class="mx-0"
                        name="payRate"
                      >
                        <input
                          style="max-width: 150px"
                          type="text"
                          class="form-control mr-1"
                          v-model="cate.payRateAll"
                          placeholder="อัตราจ่าย"
                          :readonly="!canEdit"
                        />
                      </ValidationProvider>
                      <button class="btn btn-primary mx-1">แก้ไข</button>
                      <button
                        class="btn btn-danger"
                        type="button"
                        @click="deleteBetLimits(cate)"
                      >
                        ลบ
                      </button>
                    </div>
                    <div class="w-100 text-left">
                      <span class="text-danger" v-if="v.errors.payRate">{{
                        v.errors.payRate[0]
                      }}</span>
                    </div>
                  </ValidationObserver>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BetProvider from "@/resources/bet_provider"

const betService = new BetProvider()
const pattern = /^100%$|^[0-9]%$|^[1-9][0-9]%$|^[1-9][0-9]{0,4}$|^[0-9]$/

export default {
  data() {
    return {
      isLoading: false,
      betLimits: [],
    }
  },
  computed: {
    categories: function () {
      return this.$store.state.lotto.lottoCategories.map((ele) => {
        ele.checked = false
        ele.number = ele.number ? ele.number : null
        ele.payRate = null
        ele.payRateAll = null

        let category = this.$store.state.item.halfPayoutCategorys.find(
          (item2) => item2.id == ele.id
        )
        if (category) {
          ele.payRate = category.payRate
        }

        return ele
      })
    },
    roundId: function () {
      return this.$store.state.auth.roundId
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "item_half_payout_edit"
      )
    },
  },
  watch: {
    roundId: function () {
      this.getBetLimitsRound(this.roundId)
    },
  },
  methods: {
    setPayRate(cate) {
      let item = this.$store.state.item.halfPayoutCategorys.find(
        (item) => item.id == cate.id
      )
      if (item) {
        item.payRate = cate.payRate
      } else {
        this.$store.state.item.halfPayoutCategorys.push({
          id: cate.id,
          payRate: cate.payRate,
        })
      }
    },
    getLottoCategoryList() {
      this.$store.dispatch("lotto/getLottoCategoryList")
    },
    async getBetLimitsRound() {
      if (this.roundId == 0) {
        return
      }
      this.swal.processing()
      const res = await betService.getBetLimitsRound(this.roundId)
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.betLimits = res.result.list.map((ele) => {
          ele.checked = false
          return ele
        })
        this.swal.close()
      }
    },
    filteredBetLimitsByCategory(cate) {
      return this.betLimits.filter((ele) => ele.huayCategoryId == cate.id)
    },
    toggleCateChecked(cate) {
      this.filteredBetLimitsByCategory(cate).map((ele) => {
        ele.checked = cate.checked
        return ele
      })
    },
    async addBetLimit(cate) {
      let observer = this.$refs["observer-create-" + cate.id][0]
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      if (!cate.payRate.match(pattern)) {
        this.swal.swalError("อัตราจ่ายไม่ถูกต้อง")
        return
      }

      const res = await betService.addBetLimit({
        huayRoundId: this.roundId,
        huayCategoryId: cate.id,
        number: cate.number,
        payRate: cate.payRate.includes("%")
          ? cate.payRate.slice(0, -1)
          : cate.payRate,
        isPercent: cate.payRate.includes("%"),
        reverse: cate.reverse,
      })
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          "เพิ่มสำเร็จ",
          function () {
            this.$store.dispatch("auth/forceReload")
          }.bind(this)
        )
      }
    },
    async editBetLimits(cate) {
      let observer = this.$refs["observer-edit-" + cate.id][0]
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      if (!cate.payRateAll.match(pattern)) {
        this.swal.swalError("อัตราจ่ายไม่ถูกต้อง")
        return
      }

      let checkedNumbers = this.filteredBetLimitsByCategory(cate)
        .filter((ele) => ele.checked)
        .map((ele) => ele.number)

      if (checkedNumbers.length == 0) {
        return
      }

      this.swal.processing()
      const res = await betService.editBetLimits({
        numbers: checkedNumbers,
        payRate: cate.payRateAll.includes("%")
          ? cate.payRateAll.slice(0, -1)
          : cate.payRateAll,
        isPercent: cate.payRateAll.includes("%"),
        huayCategoryId: cate.id,
        huayRoundId: this.roundId,
      })
      if (res.error) {
        this.swal.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          "แก้ไขรายการสำเร็จ",
          function () {
            this.$store.dispatch("auth/forceReload")
          }.bind(this)
        )
      }
    },

    async deleteBetLimits(cate) {
      let checkedNumbers = this.filteredBetLimitsByCategory(cate)
        .filter((ele) => ele.checked)
        .map((ele) => ele.number)
      console.log(checkedNumbers)

      if (checkedNumbers.length == 0) {
        return
      }

      this.swal.processing()
      const res = await betService.deleteBetLimits({
        numbers: checkedNumbers,
        huayCategoryId: cate.id,
        huayRoundId: this.roundId,
      })
      if (res.error) {
        this.swalError(res.error.message)
      } else {
        this.swal.swalSuccess(
          "ลบรายการสำเร็จ",
          function () {
            this.$store.dispatch("auth/forceReload")
          }.bind(this)
        )
      }
    },
    onPayRateKeyup(evt) {
      evt = evt ? evt : window.event
      var newVal = evt.target.value || ""
      if (newVal != "" && !newVal.match(pattern)) {
        evt.target.value = ""
      }
    },
  },
  mounted() {
    this.getLottoCategoryList()
    this.getBetLimitsRound()
  },
}
</script>
