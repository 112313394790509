<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['ตั้งค่าแพลตฟอร์มหวย']" />
    <platform-nav name="แพลตฟอร์ม"></platform-nav>
    <div class="mb-2">
      <button
        v-if="user.levelNumber >= 32"
        class="btn btn-success text-white mr-2"
        @click="$router.push('/platform/add')"
        v-show="canEdit"
      >
        เพิ่มแพลตฟอร์ม
      </button>
      <button
        class="btn btn-primary"
        @click="openModalForm(currentPlatform)"
        v-show="canEdit"
      >
        ตั้งค่าแพลตฟอร์ม
      </button>
    </div>
    <loading v-show="isLoading" />
    <div class="table-responsive" v-if="!isLoading">
      <table class="w-100 table table-dark table-bordered table-striped">
        <thead>
          <tr>
            <th class="text-center">#</th>
            <th class="d-flex flex-row justify-content-center">
              รหัส
              <div class="d-flex ml-2">
                <input
                  id="showPlatformName"
                  type="checkbox"
                  class="cursor-pointer mr-1 my-auto"
                  v-model="showPlatformName"
                />
                <label for="showPlatformName" class="my-auto"> แสดงชื่อ </label>
              </div>
            </th>
            <!-- <th>ธีม</th> -->
            <th class="text-center">สร้างเมื่อ</th>
            <th class="text-center">อัพเดตล่าสุด</th>
            <th class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="platform in platforms">
            <tr v-if="true" :key="platform.id">
              <td>{{ platform.id }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'PlatformListByPlatformID',
                    params: { platformId: platform.id },
                  }"
                >
                  {{ platform.code }}
                </router-link>
                <span v-if="showPlatformName"> ({{ platform.name }})</span>
              </td>
              <td>
                {{ $date(platform.createdAt).format("DD/MM/YYYY HH:mm:ss") }}
              </td>
              <td>
                {{ $date(platform.updatedAt).format("DD/MM/YYYY HH:mm:ss") }}
              </td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-info ml-auto"
                  @click="openModalForm(platform)"
                  :disabled="!canEdit"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <b-modal
      id="modal-form"
      size="xl"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      title="ตั้งค่าแพลตฟอร์ม"
    >
      <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="editPlatform()"
      >
        <template v-if="platform">
          <div class="d-flex align-items-center mb-2">
            <label :for="`name_${platform.id}`" class="mr-2"> ชื่อ: </label>
            <div class="mr-4">
              <ValidationProvider rules="required" v-slot="v">
                <input
                  :id="`name_${platform.id}`"
                  type="text"
                  class="form-control w-auto"
                  placeholder="ชื่อ"
                  v-model="platform.name"
                  maxlength="100"
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <span class="mr-1">version</span>
            <div>
              <select v-model.number="platform.version" class="form-control">
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
          </div>
          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showDescription ? null : 'collapsed'"
                :aria-expanded="platform.showDescription ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showDescription = !platform.showDescription"
                type="button"
              >
                รายละเอียด
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showDescription"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showDescription"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`depositCollapse_${platform.id}`"
              v-model="platform.showDescription"
              visible
            >
              <b-card-body>
                <div class="form-group row">
                  <label class="col-2 text-right"> ระดับ: </label>
                  <div class="col">
                    <ValidationProvider rules="required" v-slot="v">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="ชื่อเว็บ"
                        maxlength="255"
                        disabled
                        v-model="platform.level"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label :for="`title_${platform.id}`" class="col-2 text-right">
                    ชื่อเว็บ:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="required" v-slot="v">
                      <input
                        :id="`title_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อเว็บ"
                        v-model="platform.title"
                        maxlength="255"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`baseURL_${platform.id}`"
                    class="col-2 text-right"
                  >
                    url เว็บ:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="required" v-slot="v">
                      <input
                        :id="`baseURL_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="url เว็บ"
                        v-model="platform.baseURL"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`description_${platform.id}`"
                    class="col-2 text-right"
                  >
                    คำอธิบาย:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="" v-slot="v">
                      <textarea
                        :id="`description_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="คำอธิบาย"
                        rows="10"
                        v-model="platform.description"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`loginDescription_${platform.id}`"
                    class="col-2 text-right"
                  >
                    คำอธิบาย (หน้าล็อกอิน):
                  </label>
                  <div class="col">
                    <ValidationProvider rules="" v-slot="v">
                      <textarea
                        :id="`loginDescription_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="คำอธิบาย (หน้าล็อกอิน)"
                        rows="10"
                        v-model="platform.loginDescription"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`lineId_${platform.id}`"
                    class="col-2 text-right"
                  >
                    ไลน์ ID:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`lineId_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.lineId"
                        maxlength="100"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`lineUrl_${platform.id}`"
                    class="col-2 text-right"
                  >
                    ลิงค์ไลน์:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`lineUrl_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.lineUrl"
                        maxlenth
                        h="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`affiliateDescription_${platform.id}`"
                    class="col-2 text-right"
                  >
                    คำอธิบายแนะนำเพื่อน:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="" v-slot="v">
                      <textarea
                        :id="`affiliateDescription_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="คำอธิบายแนะนำเพื่อน"
                        rows="10"
                        v-model="platform.affiliateDescription"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`metaKeywords_${platform.id}`"
                    class="col-2 text-right"
                  >
                    meta keywords:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="" v-slot="v">
                      <textarea
                        :id="`metaKeywords_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="meta keywords"
                        rows="10"
                        v-model="platform.metaKeywords"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showRule ? null : 'collapsed'"
                :aria-expanded="platform.showRule ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showRule = !platform.showRule"
                type="button"
              >
                กติกา
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showRule"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showRule"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`ruleCollapse_${platform.id}`"
              v-model="platform.showRule"
              visible
            >
              <b-card-body>
                <div class="form-group row">
                  <label
                    :for="`lottoRuleOpen_${platform.id}`"
                    class="col-2 text-right"
                  >
                    กติกาหวย ปิด/เปิด:
                  </label>
                  <div class="col">
                    <select
                      class="form form-control w-auto"
                      :id="`lottoRuleOpen_${platform.id}`"
                      v-model="platform.isLottoRuleOpen"
                    >
                      <option value="false">ปิด</option>
                      <option value="true">เปิด</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`lottoRule_${platform.id}`"
                    class="col-2 text-right"
                  >
                    กติกาหวย:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="" v-slot="v">
                      <textarea
                        :id="`lottoRule_${platform.id}`"
                        type="text"
                        class="form-control"
                        rows="10"
                        v-model="platform.lottoRule"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2" v-if="platform.version == 1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showSettingGame ? null : 'collapsed'"
                :aria-expanded="platform.showSettingGame ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showSettingGame = !platform.showSettingGame"
                type="button"
              >
                เกม
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showSettingGame"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showSettingGame"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`depositCollapse_${platform.id}`"
              v-model="platform.showSettingGame"
              visible
            >
              <b-card-body>
                <div class="form-group row">
                  <label
                    :for="`gameStatus_${platform.id}`"
                    class="col-2 text-right"
                  >
                    เปิด/ปิดเกม:
                  </label>
                  <div class="col">
                    <ValidationProvider rules="required" v-slot="v">
                      <select
                        class="form-control w-auto"
                        v-model="platform.gameStatus"
                        :id="`gameStatus_${platform.id}`"
                      >
                        <option value="OPEN">เปิด</option>
                        <option value="CLOSE">ปิด</option>
                      </select>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showNews ? null : 'collapsed'"
                :aria-expanded="platform.showNews ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showNews = !platform.showNews"
                type="button"
              >
                ข่าวสาร
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showNews"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showNews"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`depositCollapse_${platform.id}`"
              v-model="platform.showNews"
              visible
            >
              <b-card-body>
                <div class="form-group row">
                  <label
                    :for="`news_status_${platform.id}`"
                    class="col-lg-2 col-xl-1 text-lg-right"
                  >
                    ปิด/เปิด:
                  </label>
                  <div class="col-lg-3">
                    <select
                      class="form form-control w-auto"
                      :id="`news_status_${platform.id}`"
                      v-model="platform.newsStatus"
                    >
                      <option value="CLOSE">ปิด</option>
                      <option value="OPEN">เปิด</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`news_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-1"
                  >
                    ข่าวสาร:
                  </label>
                  <div class="col-lg-7">
                    <ValidationProvider rules="" v-slot="v">
                      <textarea
                        :id="`news_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="ข่าวสาร"
                        rows="10"
                        v-model="platform.news"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <hr />
                <div class="form-group row">
                  <label
                    :for="`depositPopupStatus_${platform.id}`"
                    class="col-lg-2 col-xl-1 text-lg-right"
                  >
                    ปิด/เปิด:
                  </label>
                  <div class="col-lg-3">
                    <select
                      class="form form-control w-auto"
                      :id="`depositPopupStatus_${platform.id}`"
                      v-model="platform.depositPopupStatus"
                    >
                      <option value="CLOSE">ปิด</option>
                      <option value="OPEN">เปิด</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`depositPopupMsg_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-1"
                  >
                    แจ้งเตือนหน้าเติมเงิน:
                  </label>
                  <div class="col-lg-7">
                    <ValidationProvider rules="" v-slot="v">
                      <textarea
                        :id="`depositPopupMsg_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="ข่าวสาร"
                        rows="10"
                        v-model="platform.depositPopupMsg"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showDeposit ? null : 'collapsed'"
                :aria-expanded="platform.showDeposit ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showDeposit = !platform.showDeposit"
                type="button"
              >
                ฝาก / ถอน
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showDeposit"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showDeposit"
                ></i>
                <!-- {{ platform.showDeposit ? "-" : "+" }} -->
              </button>
            </b-card-header>
            <b-collapse
              :id="`depositCollapse_${platform.id}`"
              v-model="platform.showDeposit"
              visible
            >
              <b-card-body>
                <!-- <div class="form-group row">
                  <label :for="`bank_version_${platform.id}`" class="col-2 text-lg-right">
                    version:
                  </label>
                  <div class="col-lg-3">
                    <select class="form form-control w-auto" :id="`bank_version_${platform.id}`"
                      v-model.number="platform.bankVersion">
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                </div> -->
                <div class="form-group row">
                  <label
                    :for="`radioGroupDeposit_${platform.id}`"
                    class="col-lg-2 col-xl-1 col-12 text-lg-right"
                  >
                    ฝาก:
                  </label>
                  <div class="col-lg-3">
                    <b-form-radio-group
                      :id="`radioGroupDeposit_${platform.id}`"
                      v-model="platform.isDepositOpen"
                      :options="openCloseOptions"
                      :aria-describedby="''"
                      :name="`radio-deposit-options-${platform.id}`"
                    ></b-form-radio-group>
                  </div>
                  <label
                    v-if="platform.bankVersion !== 3"
                    :for="`radioGroupDeposit_bank_${platform.id}`"
                    class="col-lg-2 col-xl-1 text-lg-right"
                  >
                    ธนาคาร:
                  </label>
                  <div v-if="platform.bankVersion !== 3" class="col-lg-3">
                    <select
                      v-if="platform.bankVersion == 1"
                      class="form form-control w-auto"
                      :id="`radioGroupDeposit_bank_${platform.id}`"
                      v-model="platform.depositBankCode"
                    >
                      <option value="SCB">SCB1</option>
                      <option value="SCB2">SCB2</option>
                      <option value="KBANK">KBANK1</option>
                      <option value="KBANK2">KBANK2</option>
                      <option value="BBL">BBL1</option>
                      <option value="BBL2">BBL2</option>
                    </select>
                    <select
                      v-if="platform.bankVersion == 2"
                      class="form form-control w-auto"
                      :id="`radioGroupDeposit_bank_${platform.id}`"
                      v-model="platform.depositPlatformBankID"
                    >
                      <option
                        v-for="(item, index) in platform.platformBanks"
                        :key="platform.id + '_' + index"
                        :value="item.id"
                      >
                        {{
                          `#${item.id} ${item.bankCode} ${item.accountName} ${item.accountNumber}`
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`radioGroupWithdraw_${platform.id}`"
                    class="col-lg-2 col-xl-1 text-lg-right"
                  >
                    ถอน:
                  </label>
                  <div class="col-lg-3">
                    <b-form-radio-group
                      :id="`radioGroupWithdraw_${platform.id}`"
                      v-model="platform.isWithdrawOpen"
                      :options="openCloseOptions"
                      :aria-describedby="''"
                      :name="`radio-withdraw-options-${platform.id}`"
                    ></b-form-radio-group>
                  </div>
                  <label
                    v-if="platform.bankVersion !== 3"
                    :for="`radioGroupWithdraw_bank_${platform.id}`"
                    class="col-lg-2 col-xl-1 text-lg-right"
                  >
                    ธนาคาร:
                  </label>
                  <div v-if="platform.bankVersion !== 3" class="col-lg-3">
                    <select
                      v-if="platform.bankVersion == 1"
                      class="form form-control w-auto"
                      :id="`radioGroupDeposit_bank_${platform.id}`"
                      v-model="platform.withdrawBankCode"
                    >
                      <option value="KTB">KTB1</option>
                      <option value="KTB2">KTB2</option>
                      <option value="SCB">SCB1</option>
                      <option value="SCB2">SCB2</option>
                      <option value="KBANK">KBANK1</option>
                      <option value="KBANK2">KBANK2</option>
                    </select>
                    <select
                      v-if="platform.bankVersion == 2"
                      class="form form-control w-auto"
                      :id="`radioGroupDeposit_bank_${platform.id}`"
                      v-model="platform.withdrawPlatformBankID"
                    >
                      <option
                        v-for="(item, index) in platform.platformBanks"
                        :key="platform.id + '_' + index"
                        :value="item.id"
                      >
                        {{
                          `#${item.id} ${item.bankCode} ${item.accountName} ${item.accountNumber}`
                        }}
                      </option>
                    </select>
                  </div>
                </div>
                <hr />
                <div class="form-group row">
                  <label
                    :for="`manual_withdraw_${platform.id}`"
                    class="col-2 text-lg-right"
                  >
                    ถอนแมนนวล:
                  </label>
                  <div class="col-lg-3">
                    <select
                      class="form form-control w-auto"
                      :id="`manual_withdraw_${platform.id}`"
                      v-model="platform.isManualWithdraw"
                    >
                      <option value="true">เปิด</option>
                      <option value="false">ปิด</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`withdrawAmountMin_${platform.id}`"
                    class="col-2 col-form-label text-lg-right"
                  >
                    ถอนขั้นต่ำ:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:100|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`withdrawAmountMin_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="0"
                        @keypress="$root.onlyNumber"
                        v-model.number="platform.withdrawAmountMin"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`withdrawAmountMax_${platform.id}`"
                    class="col-2 col-form-label text-lg-right"
                  >
                    ถอนสูงสุด:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:1|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`withdrawAmountMax_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="0"
                        @keypress="$root.onlyNumber"
                        v-model.number="platform.withdrawAmountMax"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`withdraw_amount_need_approve${platform.id}`"
                    class="col-2 col-form-label text-lg-right"
                  >
                    ถอนต้องอนุมัติ:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:0|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`withdraw_amount_need_approve${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="0"
                        @keypress="$root.onlyNumber"
                        v-model.number="platform.withdrawAmountNeedApprove"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`depositAmountMin_${platform.id}`"
                    class="col-2 col-form-label text-lg-right"
                  >
                    ฝากขั้นต่ำ:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:100|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`depositAmountMin_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="0"
                        @keypress="$root.onlyNumber"
                        v-model.number="platform.depositAmountMin"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`maxApproveDepositByCall_${platform.id}`"
                    class="col-2 col-form-label text-lg-right"
                  >
                    ผูกยอดต้องอนุมัติ:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:1|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`maxApproveDepositByCall_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="0"
                        @keypress="$root.onlyNumber"
                        v-model.number="platform.maxApproveDepositByCall"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`deposit_close_msg_${platform.id}`"
                    class="col-2 col-form-label text-lg-right"
                  >
                    ข้อความปิดฝาก:
                  </label>
                  <div class="col-lg-7">
                    <ValidationProvider rules="required" v-slot="v">
                      <textarea
                        :id="`deposit_close_msg_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="คำอธิบาย"
                        rows="3"
                        v-model="platform.depositCloseMsg"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`withdraw_close_msg_${platform.id}`"
                    class="col-2 col-form-label text-lg-right"
                  >
                    ข้อความปิดถอน:
                  </label>
                  <div class="col-lg-7">
                    <ValidationProvider rules="required" v-slot="v">
                      <textarea
                        :id="`withdraw_close_msg_${platform.id}`"
                        type="text"
                        class="form-control"
                        placeholder="คำอธิบาย"
                        rows="3"
                        v-model="platform.withdrawCloseMsg"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <hr />
                <b-form-group
                  id="betBeforeWithdrawPercent-1"
                  label="เมื่อเติมเงินต้องเล่นก่อนอย่างน้อยกี่ % จึงจะถอนเงินได้"
                  label-for="betBeforeWithdrawPercent"
                >
                  <ValidationProvider
                    rules="required|numeric|min_value:0|max_value:100"
                    v-slot="v"
                  >
                    <b-form-input
                      id="betBeforeWithdrawPercent"
                      v-model="platform.betBeforeWithdrawPercent"
                      type="number"
                      placeholder="0"
                      required
                    ></b-form-input>
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  id="isBetBeforeWithdraw"
                  label="เปิด/ปิด"
                  label-for="isBetBeforeWithdraw"
                >
                  <b-form-select
                    id="isBetBeforeWithdraw"
                    v-model="platform.isBetBeforeWithdraw"
                    :options="[
                      { text: 'เปิด', value: true },
                      { text: 'ปิด', value: false },
                    ]"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body v-if="canAutoInternalTransferEdit" class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showAutoInternalTransfer ? null : 'collapsed'"
                :aria-expanded="
                  platform.showAutoInternalTransfer ? 'true' : 'false'
                "
                aria-controls="themeCollapse"
                @click="
                  platform.showAutoInternalTransfer =
                    !platform.showAutoInternalTransfer
                "
                type="button"
              >
                ตั้งค่าโอนเงินออกอัตโนมัติ
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showAutoInternalTransfer"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showAutoInternalTransfer"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`autoInternalTransfer_${platform.id}`"
              v-model="platform.showAutoInternalTransfer"
              visible
            >
              <b-card-body>
                <b-alert show variant="dark"
                  >*เงินจะโอนออกจากธนาคารฝากเท่านั้น</b-alert
                >

                <b-form-group
                  id="autoInternalTransferStatus"
                  label="เปิด/ปิด"
                  label-for="autoInternalTransferStatus"
                >
                  <b-form-select
                    id="autoInternalTransferStatus"
                    v-model="platform.autoInternalTransfer.status"
                    :options="[
                      { text: 'เปิด', value: 'OPEN' },
                      { text: 'ปิด', value: 'CLOSE' },
                    ]"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  id="bankBalance"
                  label="จำนวนเงินที่จะคงไว้ในบัญชี"
                  label-for=""
                >
                  <ValidationProvider
                    rules="numeric|min_value:0|max_value:100000"
                    v-slot="v"
                  >
                    <b-form-input
                      id="bankBalance"
                      v-model="platform.autoInternalTransfer.bankBalance"
                      type="number"
                      placeholder="0"
                    ></b-form-input>
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>

                <div class="form-group">
                  <label>ธนาคารปลายทาง</label>
                  <select
                    class="form-control mb-1"
                    id="toBankCode"
                    v-model="platform.autoInternalTransfer.toBankCode"
                    :disabled="!canEdit"
                  >
                    <option value="" disabled>กรุณาเลือกธนาคาร</option>
                    <option value="BBL">BBL ธ.กรุงเทพ</option>
                    <option value="KBANK">KBANK ธ.กสิกรไทย</option>
                    <option value="KTB">KTB ธ.กรุงไทย</option>
                    <option value="SCB">SCB ธ.ไทยพาณิชย์</option>
                    <option value="BAY">BAY ธ.กรุงศรีอยุธยา</option>
                    <option value="CIMB">CIMB ธ.ซีไอเอ็มบี</option>
                    <option value="GSB">GSB ธ.ออมสิน</option>
                    <option value="KK">KIATNAKIN ธ.เกียรตินาคิน</option>
                    <option value="LHB">LHBANK ธ.แลนด์ แอนด์ เฮ้าส์</option>
                    <option value="SC">
                      STANDARD ธ.สแตนดาร์ดชาร์เตอร์ด (ไทย)
                    </option>
                    <option value="TISCO">TISCO ธ.ทิสโก้</option>
                    <option value="TTB">TTB ธ.ทหารไทยธนชาต</option>
                    <option value="UOB">UOB ธ.ยูโอบี</option>
                    <option value="BAAC">BAAC ธกส</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>ชื่อบัญชี</label>
                  <input
                    type="text"
                    class="form-control mb-1"
                    v-model="platform.autoInternalTransfer.toAccountName"
                    placeholder="นายxx xx"
                    :readonly="!canEdit"
                    autocomplete
                  />
                </div>
                <div class="form-group">
                  <label>เลขบัญชี</label>
                  <ValidationProvider rules="min:10|numeric" v-slot="v">
                    <input
                      type="text"
                      class="form-control mb-1"
                      v-model="platform.autoInternalTransfer.toAccountNumber"
                      placeholder="1234567890"
                      :readonly="!canEdit"
                      autocomplete
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showAffiliate ? null : 'collapsed'"
                :aria-expanded="platform.showAffiliate ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showAffiliate = !platform.showAffiliate"
                type="button"
              >
                แนะนำเพื่อน
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showAffiliate"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showAffiliate"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`depositCollapse_${platform.id}`"
              v-model="platform.showAffiliate"
              visible
            >
              <b-card-body>
                <div class="form-group row">
                  <label
                    :for="`withdrawAffiliateMin_${platform.id}`"
                    class="col-2 col-form-label text-lg-right"
                  >
                    ถอนขั้นต่ำ:
                  </label>
                  <div class="col">
                    <ValidationProvider
                      rules="required|integer|min_value:1|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`withdrawAffiliateMin_${platform.id}`"
                        type="text"
                        class="form-control w-auto"
                        placeholder="0"
                        @keypress="$root.onlyNumber"
                        v-model.number="platform.withdrawAffiliateMin"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showBillSetting ? null : 'collapsed'"
                :aria-expanded="platform.showBillSetting ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showBillSetting = !platform.showBillSetting"
                type="button"
              >
                โพย
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showBillSetting"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showBillSetting"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`billSettingCollapse_${platform.id}`"
              v-model="platform.showBillSetting"
              visible
            >
              <b-card-body>
                <div class="form-group row">
                  <label
                    :for="`cancelbillMaxNumberPerRound_${platform.id}`"
                    class="col-lg-2 col-xl-2 col-form-label text-lg-right"
                  >
                    จำนวนในการยกเลิกบิล:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:1|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`cancelbillMaxNumberPerRound_${platform.id}`"
                        type="text"
                        class="form-control"
                        @keypress="$root.onlyNumber"
                        v-model.number="platform.cancelbillMaxNumberPerRound"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`cancelbillMinuteBeforeClose_${platform.id}`"
                    class="col-lg-2 col-xl-2 col-form-label text-lg-right"
                  >
                    ยกเลิกบิลภายในกี่นาทีก่อนเวลาปิด:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:0|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`cancelbillMinuteBeforeClose_${platform.id}`"
                        type="text"
                        class="form-control"
                        @keypress="$root.onlyNumber"
                        v-model.number="platform.cancelbillMinuteBeforeClose"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`cancelbillMinuteAfterBet_${platform.id}`"
                    class="col-lg-2 col-xl-2 col-form-label text-lg-right"
                  >
                    ยกเลิกบิลภายในกี่นาทีหลังแทง:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:-1|max_value:9999999"
                      v-slot="v"
                    >
                      <input
                        :id="`cancelbillMinuteAfterBet_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model.number="platform.cancelbillMinuteAfterBet"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`cancelbillMinuteAfterBet_${platform.id}`"
                    class="col-lg-2 col-xl-2 col-form-label text-lg-right"
                  >
                    แทงได้สูงสุดกี่เลข:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider
                      rules="required|integer|min_value:1|max_value:1000"
                      v-slot="v"
                    >
                      <input
                        :id="`maxHuayNumber_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model.number="platform.maxHuayNumber"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`cancelbillMinuteAfterBet_${platform.id}`"
                    class="col-lg-2 col-xl-2 col-form-label text-lg-right"
                  >
                    ข้อความแจ้งเตือนแทงเลขเกิน:
                  </label>
                  <div class="col-lg-3">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`errorMessageHuayNumberOver_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model.number="platform.errorMessageHuayNumberOver"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showImageURLs ? null : 'collapsed'"
                :aria-expanded="platform.showImageURLs ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showImageURLs = !platform.showImageURLs"
                type="button"
              >
                ลิงค์รูป
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showImageURLs"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showImageURLs"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`imageURLsCollapse_${platform.id}`"
              v-model="platform.showImageURLs"
              visible
            >
              <b-card-body>
                <div class="form-group row">
                  <label
                    :for="`bodyBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    พื้นหลัง:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <textarea
                        :id="`bodyBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        rows="10"
                        v-model="platform.bodyBackground"
                      ></textarea>
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`menuBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    พื้นหลังเมนู:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`menuBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        maxlength="512"
                        v-model="platform.menuBackground"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`creditBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    พื้นหลังเครดิต:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`creditBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        maxlength="512"
                        v-model="platform.creditBackground"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`favicon_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    ไอคอนเว็บ:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`favicon_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.favicon"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`pwaIcon_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    ไอคอนแอป:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`pwaIcon_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.pwaIcon"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    :for="`logo_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    โลโก้:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`logo_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.logo"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`logoHamburger_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    โลโก้ (เมนู):
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`logoHamburger_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.logoHamburger"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`loginBtnBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    ปุ่มล็อกอิน:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`loginBtnBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.loginBtnBackground"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`registerBtnBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    ปุ่มลงทะเบียน:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`registerBtnBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.registerBtnBackground"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`loginFormBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    พื้นหลังฟอร์มล็อกอิน:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`loginFormBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.loginFormBackground"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`topbarBorderBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    กรอบ topbar:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`topbarBorderBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.topbarBorderBackground"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`betBtnBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    ปุ่มแทง (หน้าหลัก):
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`betBtnBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.betBtnBackground"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    :for="`huayPayBackground_${platform.id}`"
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    พื้นหลังอัตราจ่าย:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <ValidationProvider rules="" v-slot="v">
                      <input
                        :id="`huayPayBackground_${platform.id}`"
                        type="text"
                        class="form-control"
                        v-model="platform.huayPayBackground"
                        maxlength="512"
                      />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    for=""
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    รูปถูกรางวัล:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <div class="row">
                      <label
                        :for="`betWinImage_${platform.id}`"
                        class="col-form-label col"
                      >
                        รูป:
                      </label>
                      <div class="col-12">
                        <ValidationProvider rules="" v-slot="v">
                          <input
                            :id="`betWinImage_${platform.id}`"
                            type="text"
                            class="form-control"
                            v-model="platform.betWinImage.img"
                            maxlength="512"
                          />
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <label
                        :for="`betWinImageSm_${platform.id}`"
                        class="col-form-label col"
                      >
                        รูปเล็ก:
                      </label>
                      <div class="col-lg-12">
                        <ValidationProvider rules="" v-slot="v">
                          <input
                            :id="`betWinImageSm_${platform.id}`"
                            type="text"
                            class="form-control"
                            v-model="platform.betWinImage.imgSm"
                            maxlength="512"
                          />
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    for=""
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    โฆษณาหน้าล็อกอิน:
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <template v-for="(item, index) in platform.adsLoginItems">
                      <div v-if="true" class="row" :key="'ads-img-' + index">
                        <div class="col-12 d-flex">
                          <label
                            :for="`adsLoginItems_${platform.id}_${index}`"
                            class="col-form-label col"
                          >
                            รูป ({{ index + 1 }}):
                          </label>
                          <div
                            class="d-flex align-items-center cursor-pointer"
                            @click="platform.adsLoginItems.splice(index, 1)"
                          >
                            <i class="ml-auto fa fa-trash text-danger"></i>
                          </div>
                        </div>

                        <div class="col-12">
                          <ValidationProvider rules="" v-slot="v">
                            <input
                              :id="`adsLoginItems_${platform.id}_${index}`"
                              type="text"
                              class="form-control"
                              v-model="item.img"
                              maxlength="512"
                            />
                            <span class="text-danger">{{ v.errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div v-if="true" class="row" :key="'ads-img-sm-' + index">
                        <label
                          :for="`adsLoginItemsSm_${platform.id}_${index}`"
                          class="col-form-label col"
                        >
                          รูปเล็ก ({{ index + 1 }}):
                        </label>
                        <div class="col-lg-12 mb-3">
                          <ValidationProvider rules="" v-slot="v">
                            <input
                              :id="`adsLoginItemsSm_${platform.id}_${index}`"
                              type="text"
                              class="form-control"
                              v-model="item.imgSm"
                              maxlength="512"
                            />
                            <span class="text-danger">{{ v.errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </template>
                    <button
                      v-if="platform.adsLoginItems.length < 10"
                      type="button"
                      class="btn btn-warning mt-2"
                      @click="
                        platform.adsLoginItems.push({
                          img: '',
                          imgSm: '',
                        })
                      "
                    >
                      เพิ่ม
                    </button>
                  </div>
                </div>
                <div class="form-group row" v-if="platform.version == 1">
                  <label
                    for=""
                    class="col-form-label text-lg-right col-lg-2 col-xl-1"
                  >
                    โฆษณาหน้าล็อกอิน (ล่าง):
                  </label>
                  <div class="col-lg-8 col-xl-7">
                    <div class="row">
                      <label
                        :for="`adsLoginBottom_${platform.id}`"
                        class="col-form-label col"
                      >
                        รูป:
                      </label>
                      <div class="col-12">
                        <ValidationProvider rules="" v-slot="v">
                          <input
                            :id="`adsLoginBottom_${platform.id}`"
                            type="text"
                            class="form-control"
                            v-model="platform.adsLoginBottom.img"
                            maxlength="512"
                          />
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <label
                        :for="`adsLoginBottomSm_${platform.id}`"
                        class="col-form-label col"
                      >
                        รูปเล็ก:
                      </label>
                      <div class="col-lg-12">
                        <ValidationProvider rules="" v-slot="v">
                          <input
                            :id="`adsLoginBottomSm_${platform.id}`"
                            type="text"
                            class="form-control"
                            v-model="platform.adsLoginBottom.imgSm"
                            maxlength="512"
                          />
                          <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1" v-if="platform.version == 1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <button
                class="btn btn btn-info w-100 text-left"
                :class="platform.showTheme ? null : 'collapsed'"
                :aria-expanded="platform.showTheme ? 'true' : 'false'"
                aria-controls="themeCollapse"
                @click="platform.showTheme = !platform.showTheme"
                type="button"
              >
                ธีม
                <i
                  class="fa fa-chevron-down float-right"
                  v-if="!platform.showTheme"
                ></i>
                <i
                  class="fa fa-chevron-up float-right"
                  v-if="platform.showTheme"
                ></i>
              </button>
            </b-card-header>
            <b-collapse
              :id="`theme-collapse-${platform.id}`"
              v-model="platform.showTheme"
              visible
            >
              <b-card-body v-if="platform.version == 1">
                <div class="row">
                  <div class="col-12" v-for="item in colors" :key="item.key">
                    <div class="form-group row">
                      <label
                        :for="`color_${item.key}_${platform.id}`"
                        class="col-form-label col-3 text-lg-right"
                      >
                        {{ item.label }}:
                      </label>
                      <div class="col-5 d-flex align-items-center">
                        <color-picker
                          :id="`color_${item.key}_${platform.id}`"
                          v-model="platform[item.key]"
                          :color="platform[item.key]"
                          class="w-100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </template>
      </ValidationObserver>
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="editPlatform()"
          >บันทึก</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColourPicker"
import PlatformProvider from "@/resources/platform_provider"

const platformService = new PlatformProvider()
export default {
  components: {
    "color-picker": ColorPicker,
  },
  data() {
    return {
      platformId: null,
      colors: [
        {
          label: "Primary",
          key: "colorPrimary",
        },
        {
          label: "Secondary",
          key: "colorSecondary",
        },
        {
          label: "Success",
          key: "colorSuccess",
        },
        {
          label: "Info",
          key: "colorInfo",
        },
        {
          label: "Warning",
          key: "colorWarning",
        },
        {
          label: "Danger",
          key: "colorDanger",
        },
        {
          label: "Light",
          key: "colorLight",
        },
        {
          label: "Dark",
          key: "colorDark",
        },
        {
          label: "Blue",
          key: "colorBlue",
        },
        {
          label: "Red",
          key: "colorRed",
        },

        {
          label: "Green",
          key: "colorGreen",
        },

        {
          label: "Gray",
          key: "colorGray",
        },

        {
          label: "รายการหวย",
          key: "colorHuayListItem",
        },
        {
          label: "menu hamburger",
          key: "colorMenuHamburger",
        },
        {
          label: "ปุ่ม สล๊อต&คาสิโน",
          key: "colorGameIndex",
        },
      ],
      openCloseOptions: [
        {
          text: "เปิด",
          value: true,
        },
        {
          text: "ปิด",
          value: false,
        },
      ],
      platforms: [],
      isLoading: false,
      showPlatformName: true,
      platform: null,
      currentPlatform: null,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "platform_list_edit"
      )
    },
    canAutoInternalTransferEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "auto_internal_transfer_edit"
      )
    },
    betExceedPercentList() {
      const betExceedPercentList = []
      for (let i = 0; i <= 100; i += 0.5) {
        betExceedPercentList.push(i)
      }
      return betExceedPercentList
    },
  },
  mounted() {
    this.platformId = this.$route.params.platformId
    this.getPlatformList()
    this.getPlatform()
  },
  watch: {
    $route(to, from) {
      if (
        to.name != from.name ||
        to.params.platformId != from.params.platformId
      ) {
        this.$store.dispatch("auth/forceReload")
      }
    },
  },
  methods: {
    openModalForm(platform) {
      this.platform = platform
      this.$bvModal.show("modal-form")
    },
    async getPlatform() {
      this.isLoading = true
      const res = await platformService.getPlatform()
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.isLoading = false

      this.currentPlatform = res.result
    },
    async getPlatformList() {
      this.isLoading = true
      const res = await platformService.getPlatformList(this.platformId)
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.platforms = res.result.list?.map((ele) => {
        ele.expanded = false
        ele.showTheme = false
        ele.showDeposit = false
        ele.showBillSetting = false
        ele.showImageURLs = false
        return ele
      })
      this.isLoading = false
    },
    async editPlatform() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      this.platform.isManualWithdraw = this.platform.isManualWithdraw === "true"
      this.platform.isLottoRuleOpen = this.platform.isLottoRuleOpen === "true"

      const res = await platformService.editPlatform(this.platform)
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.swal.swalSuccess("แก้ไขข้อมูลสำเร็จ", () => {
        this.$store.dispatch("auth/forceReload")
      })
    },
  },
}
</script>
