var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"sidebar-bg-dark",attrs:{"role":"navigation","id":"navigation"}},[_c('div',{staticClass:"scrollbar scrollbar-use-navbar scrollbar-bg-dark"},[_c('Header'),_c('div',{staticClass:"mt-2",attrs:{"id":"account-summary"}},[_c('ul',{staticStyle:{"border":"0"}},[_c('li',[_c('span',{staticClass:"prop"},[_vm._v("ชื่อผู้ใช้:")]),_c('span',[_vm._v(_vm._s(_vm.currentUser.username))])]),_c('li',[_c('span',{staticClass:"prop"},[_vm._v("ระดับ:")]),_c('span',[_vm._v(_vm._s(_vm.currentUser.levelTH))])]),_c('li',[_c('span',{staticClass:"prop"},[_vm._v(" แพลตฟอร์ม:")]),_c('span',[_vm._v(_vm._s(_vm.currentUser.platformName))])]),_c('li',[_c('span',{staticClass:"prop"},[_vm._v("เครดิต:")]),_c('span',{class:{
              positive: _vm.currentUser.creditBalance >= 0,
              negative: _vm.currentUser.creditBalance < 0,
            }},[_vm._v(_vm._s(_vm.util.formatMoneyNormal(_vm.currentUser.creditBalance)))])])])]),_c('ul',{staticClass:"list list-unstyled list-bg-dark mb-0"},[_c('li',{staticClass:"list-item"},[_c('router-link',{staticClass:"list-link",attrs:{"to":"/welcome","exact-active-class":"link-current active"}},[_c('span',{staticClass:"list-icon"},[_c('i',{staticClass:"fa fa-chart-line"})]),_vm._v(" แดชบอร์ด ")])],1)]),_vm._l((_vm.menuItemsFilter),function(item,index){return _c('ul',{key:index,staticClass:"list list-unstyled list-bg-dark mb-0",attrs:{"id":"list-sidebar"}},[_c('li',{staticClass:"list-item"},[_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('a',{staticClass:"list-link link-arrow text-uppercase",class:{
                'up transition active rotate link-current': item.active,
              },attrs:{"href":"#"}},[_c('span',{staticClass:"list-icon"},[_c('i',{class:item.icon})]),_vm._v(_vm._s(item.name)+" ")]),_c('ul',{staticClass:"list-unstyled list-hidden px-0",style:({ display: item.active ? 'block' : 'none' })},_vm._l((_vm.subMenu(item.subMenus)),function(itemSub,indexSub){return _c('li',{key:`sub` + indexSub},[_c('router-link',{staticClass:"list-link",attrs:{"to":itemSub.path,"exact-active-class":"link-current up active down","disabled":""}},[_vm._v(" "+_vm._s(itemSub.name)+" ")])],1)}),0)])])])])}),_c('ul',{staticClass:"list list-unstyled list-bg-dark mb-0",attrs:{"id":"list-sidebar"}},[_c('li',{staticClass:"list-item"},[_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('router-link',{staticClass:"list-link",attrs:{"to":"/profile/setting","exact-active-class":"link-current up active down"}},[_c('span',{staticClass:"list-icon"},[_c('i',{staticClass:"fa fa-user"})]),_vm._v(" ตั้งค่าบัญชี ")])],1),_c('li',[_c('a',{staticClass:"list-link up",attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._m(0),_vm._v("ออกจากระบบ")])])])])])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"list-icon"},[_c('i',{staticClass:"fa fa-sign-out-alt"})])
}]

export { render, staticRenderFns }