import { render, staticRenderFns } from "./LottoTypeForm.vue?vue&type=template&id=b657d618&scoped=true&"
import script from "./LottoTypeForm.vue?vue&type=script&lang=js&"
export * from "./LottoTypeForm.vue?vue&type=script&lang=js&"
import style0 from "./LottoTypeForm.vue?vue&type=style&index=0&id=b657d618&prod&lang=scss&"
import style1 from "./LottoTypeForm.vue?vue&type=style&index=1&id=b657d618&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b657d618",
  null
  
)

export default component.exports