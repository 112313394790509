<template>
  <div>
    <bread-cum
      v-bind:breadcrumbItems="['รายการแทง', 'ตั้งค่ารับของแยกตามชนิด']"
    />
    <div class="alert alert-info" role="alert">
      กรุณาใส่จำนวนเงินที่ท่านต้องการถือสู้ <span class="negative">ต่อตัว</span>
      ของแต่ละประเภทลงในช่องด้านล่าง เพื่อเปิดรับของ
      หากไม่ต้องการถือสู้ในประเภทใด ๆ ให้ใส่ 0
    </div>
    <loading v-show="isLoading" />
    <div class="table-responsive" v-show="!isLoading">
      <ValidationObserver ref="observer">
        <table
          class="
            table table-sm table-bordered table-hover table-dark
            text-center
          "
        >
          <thead>
            <tr>
              <th style="min-width: 100px">ชนิด</th>
              <th v-for="(item, index) in categorys" :key="index">
                {{ item.name }}
              </th>
              <th colspan="2">
                <button
                  class="btn btn-primary"
                  @click="updateBetReceiveCategory(null, true)"
                  v-show="canEdit"
                >
                  บันทึกที่เลือก
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-gold">กรอกทุกแถว</td>
              <td v-for="(item, index) in betLimitAmounts" :key="index">
                <input
                :readonly="!canEdit"
                  type="text"
                  class="form-control"
                  v-model.number="item.amount"
                  style="min-width: 53px"
                />
              </td>
              <td>
                <button
                  class="btn btn-primary"
                  @click="updateBetReceiveCategory(null, false)"
                  v-show="canEdit"
                >
                  บันทึก
                </button>
              </td>
              <td>
                <input
                  type="checkbox"
                  v-model="checkAll"
                  @change="toggleCheckAll"
                  :disabled="!canEdit"
                />
              </td>
            </tr>
            <template v-for="(huayGroup, index) in huayGroups">
              <tr :key="index * -1">
                <td colspan="11" class="bg-primary">{{ huayGroup.name }}</td>
              </tr>
              <tr
                :key="huayType.id"
                v-for="huayType in filterHuayTypes(huayGroup.id)"
              >
                <td>{{ huayType.name }}</td>
                <td
                  v-for="(
                    betReceiveCategory, index2
                  ) in filterBetReceiveCategorys(huayType.id)"
                  :key="index2"
                >
                  <ValidationProvider
                    :rules="`required|min_value:0`"
                    v-slot="v"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model.number="betReceiveCategory.amount"
                      style="min-width: 53px"
                      @keypress="$root.onlyNumber"
                      :readonly="!canEdit"
                    />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="updateBetReceiveCategory(huayType, false)"
                    v-show="canEdit"
                  >
                    บันทึก
                  </button>
                </td>
                <td><input type="checkbox" v-model="huayType.checked" :disabled="!canEdit"/></td>
              </tr>
            </template>
          </tbody>
        </table>
      </ValidationObserver>
    </div>
  </div>
</template>

<style scoped>
input {
  font-size: 0.75rem;
  text-align: right;
}
button {
  font-size: 0.75rem;
}
</style>

<script>
export default {
  data() {
    return {
      checkAll: false,
      betLimitAmounts: [],
      betReceiveCategorys: [],
      huayTypes: [],
      huayGroups: [],
      categorys: [],
      isLoading: true,
    }
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("item_limit_setting_edit") 
    }
  },
  methods: {
    toggleCheckAll() {
      this.huayTypes.forEach((item) => (item.checked = this.checkAll))
    },
    filterBetReceiveCategorys(huayTypeId) {
      return this.betReceiveCategorys.filter(
        (item) => item.huayTypeId == huayTypeId
      )
    },
    filterHuayTypes(huayGroupId) {
      return this.huayTypes.filter((item) => item.huayGroupId == huayGroupId)
    },
    async updateBetReceiveCategory(huayType, onlyChecked) {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      let data = {}

      if (huayType) {
        data = this.betReceiveCategorys.filter(
          (item) => item.huayTypeId == huayType.id
        )
      } else if (onlyChecked) {
        data = this.betReceiveCategorys
          .filter((item) => {
            let huayType = this.huayTypes.find(
              (item2) => item.huayTypeId == item2.id
            )

            return huayType ? huayType.checked : false
          })
          .map((item) => {
            let res = this.betLimitAmounts.find(
              (item2) => item2.huayCategoryId == item.huayCategoryId
            )
            if (res && res.amount != null) {
              item.amount = res.amount
            }
            return item
          })
      } else {
        data = this.betReceiveCategorys.map((item) => {
          let res = this.betLimitAmounts.find(
            (item2) => item2.huayCategoryId == item.huayCategoryId
          )
          if (res && res.amount != null) {
            item.amount = res.amount
          }
          return item
        })
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "bet/limit-by-category",
          data: data,
        })

        await this.$swal({
          icon: "success",
          text: "ตั้งค่ารับของสำเร็จ",
        })

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)

        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: e.response.data.description,
        })
      }
    },
  },
  async mounted() {
    try {
      let p1 = this.axios({
        method: "get",
        url: "bet/limit-by-category",
      })
      let p2 = this.axios.get("huay/category/list")

      let [res, resCategory] = await Promise.all([p1, p2])

      this.categorys = resCategory.data.data.list

      let data = res.data.data

      this.huayGroups = data.huayGroups

      data.huayTypes.forEach((item) => {
        item.checked = false

        let found = data.betReceiveCategorys.find(
          (item2) => item.id == item2.huayTypeId
        )
        if (found) {
          return
        }

        this.categorys.forEach((item2) => {
          data.betReceiveCategorys.push({
            huayCategoryId: item2.id,
            amount: 0,
            huayTypeId: item.id,
          })
        })
      })

      this.huayTypes = data.huayTypes

      this.betReceiveCategorys = data.betReceiveCategorys

      this.categorys.forEach((item) => {
        this.betLimitAmounts.push({
          huayCategoryId: item.id,
          amount: null,
        })
      })

      this.isLoading = false
    } catch (err) {
      console.log(err)
      this.$swal({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
      })
    }
  },
}
</script>