<template>
  <div>
    <div class="card border-primary" v-if="!canEdit">
      <h5 class="card-header border-primary bg-primary">ข่าวสาร</h5>
      <div class="text-center h1 my-5">ยินดีต้อนรับสมาชิกทุกท่าน</div>
    </div>
    <template v-if="canEdit">
      <div class="card border-primary mb-3" style="width: 700px">
        <div class="card-header border-primary bg-primary text-white p-2 h6">
          ตัวเลือกการค้นหา
        </div>
        <div class="card-body">
          <div class="d-flex mb-2">
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model="fromDate"
              placeholder="เลือกวัน"
              label-no-date-selected="ยังไม่เลือก"
              today-button
              reset-button
              close-button
              label-close-button="ปิด"
              label-reset-button="รีเซ็ต"
              label-today="วันนี้"
              label-today-button="วันนี้"
            ></b-form-datepicker>
            <span class="mx-2 my-auto">ถึง</span>
            <b-form-datepicker
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model="toDate"
              placeholder="เลือกวัน"
              label-no-date-selected="ยังไม่เลือก"
              today-button
              reset-button
              close-button
              label-close-button="ปิด"
              label-reset-button="รีเซ็ต"
              label-today="วันนี้"
              label-today-button="วันนี้"
            ></b-form-datepicker>
            <button class="btn btn-primary ml-2" @click="loadData">
              ค้นหา
            </button>
          </div>

          <div class="d-flex">
            <button
              v-for="(item, index) in searchDateButtons"
              :key="index"
              class="btn btn-sm btn-warning mr-2"
              @click="setDateRange(item.key)"
            >
              {{ item.name }}
            </button>
            <button
              class="btn"
              :class="{ 'btn-outline-info': !showAll, 'btn-info': showAll }"
              @click="showAll = !showAll"
            >
              <span v-if="!showAll">แสดงรวมหวยเกม</span>
              <span v-if="showAll">แสดงแยกหวยเกม</span>
            </button>
          </div>
        </div>
      </div>
      <template v-if="!showAll">
        <div class="row mb-4">
          <div class="col-4 mb-4">
            <WidgetDashboard
              header="ยอดซื้อ"
              :body="dashboardInfo.reportBetAll.buyAmountReceive"
              icon="money-bill-alt"
            >
            </WidgetDashboard>
          </div>
          <div class="col-4 mb-4">
            <WidgetDashboard
              header="ยอดถูก"
              :body="dashboardInfo.reportBetAll.payAmount"
              icon="money-bill-alt"
            >
            </WidgetDashboard>
          </div>
          <div class="col-4 mb-4">
            <WidgetDashboard
              header="ส่วนลด"
              :body="dashboardInfo.reportBetAll.discountAmount"
              icon="money-bill-alt"
            >
            </WidgetDashboard>
          </div>
          <div class="col-4 mb-4">
            <WidgetDashboard
              header="แนะนำเพื่อน"
              :body="dashboardInfo.reportBetAll.affiliateAmount"
              icon="money-bill-alt"
            ></WidgetDashboard>
          </div>
          <div class="col-4 mb-4">
            <WidgetDashboard
              header="ค่าคอมเซียน"
              :body="dashboardInfo.reportBetAll.sianCommision"
              icon="money-bill-alt"
            >
            </WidgetDashboard>
          </div>
          <div class="col-4 mb-4">
            <WidgetDashboard
              header="รายได้รวม"
              :body="dashboardInfo.reportBetAll.income"
              icon="money-bill-alt"
            >
            </WidgetDashboard>
          </div>
        </div>
      </template>
      <template v-if="showAll">
        <div class="card border-info mb-3">
          <div class="card-header border-info bg-info p-2">
            <i class="fas fa-chart-bar"></i> แพ้ชนะรวม
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ยอดซื้อ"
                  :body="dashboardInfo.reportBetAll.buyAmountReceive"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ยอดถูก"
                  :body="dashboardInfo.reportBetAll.payAmount"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ส่วนลด"
                  :body="dashboardInfo.reportBetAll.discountAmount"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="แนะนำเพื่อน"
                  :body="dashboardInfo.reportBetAll.affiliateAmount"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ค่าคอมเซียน"
                  :body="dashboardInfo.reportBetAll.sianCommision"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="รายได้รวม"
                  :body="dashboardInfo.reportBetAll.income"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-info mb-3">
          <div class="card-header border-info bg-info p-2">
            <i class="fas fa-dice"></i> แพ้ชนะหวย
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ยอดซื้อ"
                  :body="dashboardInfo.sumBuyAmountReceive"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ยอดถูก"
                  :body="dashboardInfo.sumPayAmount"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ส่วนลด"
                  :body="dashboardInfo.sumDiscountAmount"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="แนะนำเพื่อน"
                  :body="dashboardInfo.sumAffiliateAmount"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ค่าคอมเซียน (หวย)"
                  :body="dashboardInfo.sumSianCommissionHuay"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="รายได้รวม"
                  :body="dashboardInfo.sumIncome"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-info mb-4">
          <div class="card-header border-info bg-info p-2">
            <i class="fas fa-dice"></i> แพ้ชนะเกม
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ยอดซื้อ"
                  :body="dashboardInfo.reportGameBet.stakeReceive"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ยอดถูก"
                  :body="dashboardInfo.reportGameBet.payoutReceive"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="แนะนำเพื่อน"
                  :body="dashboardInfo.reportGameBet.affiliateReceive"
                  icon="money-bill-alt"
                ></WidgetDashboard>
              </div>

              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="ค่าคอมเซียน (เกม)"
                  :body="dashboardInfo.sumSianCommissionGame"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
              <div class="col-4 mb-4">
                <WidgetDashboard
                  header="รายได้รวม"
                  :body="dashboardInfo.reportGameBet.income"
                  icon="money-bill-alt"
                >
                </WidgetDashboard>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div
          class="col-4 mb-4"
          v-for="(item, index) in this.dashboardInfo.bankAccountBalances"
          :key="index"
        >
          <WidgetBank
            :header="item.header"
            :body="item.balance"
            :bank-code="item.bankCode"
          ></WidgetBank>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-4 mb-4">
          <WidgetDashboard header="ยอดฝาก (ธนาคาร)" :body="dashboardInfo.sumBankStatementDeposit" icon="hand-holding-usd">
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard header="ยอดถอน (ธนาคาร)" :body="dashboardInfo.sumBankStatementWithdraw"
            icon="hand-holding-usd">
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4"></div> -->
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="ยอดฝาก"
            :body="dashboardInfo.sumDeposit"
            icon="hand-holding-usd"
          >
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="ยอดถอน"
            :body="dashboardInfo.sumWithdraw"
            icon="hand-holding-usd"
          >
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="รับโบนัส"
            :body="dashboardInfo.sumDepositBonus"
            icon="hand-holding-usd"
          >
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="สมาชิกที่ใช้งานระบบอยู่"
            :body="dashboardInfo.onlineUser"
            icon="users"
          >
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="สมาชิกใหม่"
            :body="dashboardInfo.newUser"
            icon="user-plus"
          >
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="จำนวนสมาชิกที่มีผู้แนะนำ"
            :body="dashboardInfo.newUserByAffiliate"
            icon="user-plus"
          >
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="สมาชิกทั้งหมด"
            :body="dashboardInfo.allUser"
            icon="user-plus"
          >
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="จำนวนเครดิตทั้งหมดในระบบ"
            :body="dashboardInfo.sumCreditBalance"
            icon="money-bill-alt"
          >
          </WidgetDashboard>
        </div>
        <div class="col-4 mb-4">
          <WidgetDashboard
            header="จำนวน af ทั้งหมดในระบบ"
            :body="dashboardInfo.sumAFBalance"
            icon="money-bill-alt"
          >
          </WidgetDashboard>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import WidgetDashboard from "@/components/WidgetDashboard.vue"
import WidgetBank from "@/components/WidgetBank.vue"

export default {
  components: { WidgetDashboard, WidgetBank },
  data() {
    return {
      dashboardInfo: {
        reportGameBet: {},
        reportBetAll: {},
      },
      fromDate: this.$date().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      toDate: this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      searchDateButtons: [
        {
          name: "วันนี้",
          key: "TODAY",
        },
        {
          name: "เมื่อวาน",
          key: "YESTERDAY",
        },
        {
          name: "สัปดาห์นี้",
          key: "WEEK",
        },
        {
          name: "สัปดาห์ที่แล้ว",
          key: "LASTWEEK",
        },
        {
          name: "เดือนนี้",
          key: "MONTH",
        },
        {
          name: "เดือนที่แล้ว",
          key: "LASTMONTH",
        },
      ],
      showAll: false,
    }
  },
  computed: {
    canEdit() {
      return this.$store.state.auth.user?.userData?.permissions?.includes(
        "dashboard_view"
      )
    },
    user() {
      return this.$store.state.auth.user.userData
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    setDateRange(key) {
      if (key == "TODAY") {
        this.fromDate = this.$date()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "YESTERDAY") {
        this.fromDate = this.$date()
          .add(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "WEEK") {
        this.fromDate = this.$date()
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTWEEK") {
        this.fromDate = this.$date()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "MONTH") {
        this.fromDate = this.$date()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTMONTH") {
        this.fromDate = this.$date()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      this.loadData()
    },
    getClassFromNumber(number) {
      if (number < 0) {
        return "negative"
      } else if (number > 0) {
        return "positive"
      }

      return ""
    },
    async loadData() {
      if (!this.canEdit) {
        return
      }

      // this.swal.processing()

      try {
        let res = await this.axios({
          method: "get",
          url: "dashboard/over-all",
          params: {
            fromDate: this.$date(this.fromDate)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            toDate: this.$date(this.toDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        })

        this.dashboardInfo = res.data.data

        this.dashboardInfo.sumWithdraw =
          parseInt(this.dashboardInfo.sumWithdraw) * -1
        this.dashboardInfo.sumBuyAmountReceive = parseInt(
          this.dashboardInfo.sumBuyAmountReceive
        )
        this.dashboardInfo.sumDiscountAmount = parseInt(
          this.dashboardInfo.sumDiscountAmount
        )
        this.dashboardInfo.sumAffiliateAmount = parseInt(
          this.dashboardInfo.sumAffiliateAmount
        )
        this.dashboardInfo.sumPayAmount = parseInt(
          this.dashboardInfo.sumPayAmount
        )

        this.dashboardInfo.sumIncome =
          this.dashboardInfo.sumBuyAmountReceive +
          this.dashboardInfo.sumDiscountAmount +
          this.dashboardInfo.sumAffiliateAmount +
          this.dashboardInfo.sumSianCommissionHuay +
          this.dashboardInfo.sumPayAmount

        this.dashboardInfo.reportGameBet.income = parseInt(
          this.dashboardInfo.reportGameBet.income +
            this.dashboardInfo.sumSianCommissionGame
        )
        let reportGameBet = this.dashboardInfo.reportGameBet

        this.dashboardInfo.reportBetAll.sianCommision = parseInt(
          this.dashboardInfo.sumSianCommissionHuay +
            this.dashboardInfo.sumSianCommissionGame
        )
        let reportBetAll = this.dashboardInfo.reportBetAll

        this.dashboardInfo.reportGameBet = reportGameBet
        this.dashboardInfo.reportBetAll = reportBetAll

        // this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
}
</script>
