<template>
  <nav role="navigation" class="sidebar-bg-dark" id="navigation">
    <div class="scrollbar scrollbar-use-navbar scrollbar-bg-dark">
      <Header />
      <div id="account-summary" class="mt-2">
        <ul style="border: 0">
          <li>
            <span class="prop">ชื่อผู้ใช้:</span
            ><span>{{ currentUser.username }}</span>
          </li>
          <li>
            <span class="prop">ระดับ:</span
            ><span>{{ currentUser.levelTH }}</span>
          </li>
          <li>
            <span class="prop"> แพลตฟอร์ม:</span>
            <span>{{ currentUser.platformName }}</span>
          </li>
          <li>
            <span class="prop">เครดิต:</span>
            <span
              :class="{
                positive: currentUser.creditBalance >= 0,
                negative: currentUser.creditBalance < 0,
              }"
              >{{ util.formatMoneyNormal(currentUser.creditBalance) }}</span
            >
          </li>
        </ul>
      </div>
      <ul class="list list-unstyled list-bg-dark mb-0">
        <li class="list-item">
          <router-link
            to="/welcome"
            class="list-link"
            exact-active-class="link-current active"
          >
            <span class="list-icon"><i class="fa fa-chart-line"></i></span>
            แดชบอร์ด
          </router-link>
        </li>
        <!-- <li class="list-item" v-if="currentUser.levelNumber >= 99">
          <router-link
            to="/graph"
            class="list-link"
            exact-active-class="link-current"
          >
            <span class="list-icon"><i class="fa fa-chart-line"></i></span>
            กราฟ
          </router-link>
        </li> -->
      </ul>

      <ul
        id="list-sidebar"
        class="list list-unstyled list-bg-dark mb-0"
        v-for="(item, index) in menuItemsFilter"
        :key="index"
      >
        <li class="list-item">
          <ul class="list-unstyled">
            <li>
              <a
                href="#"
                class="list-link link-arrow text-uppercase"
                :class="{
                  'up transition active rotate link-current': item.active,
                }"
              >
                <span class="list-icon"> <i :class="item.icon"></i> </span
                >{{ item.name }}
              </a>

              <ul
                class="list-unstyled list-hidden px-0"
                :style="{ display: item.active ? 'block' : 'none' }"
              >
                <li
                  v-for="(itemSub, indexSub) in subMenu(item.subMenus)"
                  :key="`sub` + indexSub"
                >
                  <router-link
                    :to="itemSub.path"
                    class="list-link"
                    exact-active-class="link-current up active down"
                    disabled
                  >
                    {{ itemSub.name }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="list list-unstyled list-bg-dark mb-0" id="list-sidebar">
        <li class="list-item">
          <ul class="list-unstyled">
            <li>
              <router-link
                to="/profile/setting"
                class="list-link"
                exact-active-class="link-current up active down"
              >
                <span class="list-icon"><i class="fa fa-user"></i></span>
                ตั้งค่าบัญชี
              </router-link>
            </li>
            <li>
              <a href="#" class="list-link up" @click="logout"
                ><span class="list-icon"
                  ><i class="fa fa-sign-out-alt"></i></span
                >ออกจากระบบ</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style>
.sidebar-bg-dark {
  background-color: rgb(42, 45, 53);
}

#account-summary {
  color: white;
}

#sidebar.menu-min #account-summary {
  display: none;
}

#account-summary ul {
  list-style: none;
  padding: 5px;
  margin: 0;
  border-top: 1px solid #e5e5e5;
  text-align: left !important;
  /* white-space: nowrap; */
}

#account-summary ul li {
  font-size: 1rem;
}

#account-summary ul li span.prop {
  display: inline-block;
  width: 87px;
  text-align: right;
  margin-right: 4px;
}

#account-summary ul li span {
  font-weight: bold;
}

#list-sidebar li {
  font-size: 1rem;
  white-space: nowrap;
}
</style>

<script>
import $ from "jquery"
import PerfectScrollbar from "perfect-scrollbar"
import Header from "@/components/Header.vue"

export default {
  components: { Header },
  name: "Sidebar",
  data() {
    return {
      menuItems: [
        {
          name: "รายการแทงหวย",
          icon: "fa fa-table",
          active: false,
          excludeLevel: [31, 100],
          platformLevel: 1,
          subMenus: [
            {
              name: "ดูของรวม / คาดคะเนได้เสีย",
              path: "/items/overall",
              level: 10,
              excludeLevel: [],
              permission: "item_overall_view",
              platformLevel: 1,
            },
            {
              name: "รอผลเดิมพัน",
              path: "/items/by-date",
              level: 10,
              excludeLevel: [],
              permission: "report_waiting_by_date_view",
              platformLevel: 1,
            },
            {
              name: "ดูของ แพลตฟอร์ม/ประเภท",
              path: "/items/by-round",
              level: 10,
              excludeLevel: [],
              permission: "item_by_round_view",
            },
            {
              name: "ตั้งค่าการรับของ",
              path: "/items/settings",
              level: 10,
              excludeLevel: [],
              permission: "item_setting_view",
              platformLevel: 1,
            },
            {
              name: "ตั้งค่ารับของแยกตามชนิดหวย",
              path: "/items/limit-settings",
              level: 10,
              excludeLevel: [],
              permission: "item_limit_setting_view",
              platformLevel: 1,
            },
            {
              name: "ตั้งค่าอัตราจ่าย",
              path: "/items/half-payouts",
              level: 10,
              excludeLevel: [],
              permission: "item_half_payout_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "แพ้ชนะหวย",
          icon: "fa fa-chart-bar",
          active: false,
          level: 10,
          excludeLevel: [31, 100],
          platformLevel: 1,
          subMenus: [
            {
              name: "แพ้-ชนะ แพลตฟอร์ม/ประเภท",
              path: "/reports/by-member",
              level: 10,
              excludeLevel: [],
              permission: "report_by_member_view",
              platformLevel: 1,
            },
            {
              name: "แพ้-ชนะ สุทธิ",
              path: "/reports/by-date",
              level: 10,
              excludeLevel: [],
              permission: "report_by_date_view",
              platformLevel: 1,
            },
            {
              name: "ผลการออกรางวัล",
              path: "/reports/game-result",
              level: 10,
              excludeLevel: [],
              permission: "report_game_result_view",
              platformLevel: 1,
            },
          ],
        },
        // {
        //   name: "แพ้ชนะเกม",
        //   icon: "fa fa-chart-bar",
        //   active: false,
        //   level: 10,
        //   excludeLevel: [31, 100],
        //   subMenus: [
        //     {
        //       name: "แพ้-ชนะ ค่ายเกม",
        //       path: "/reports/game-product/by-date",
        //       level: 10,
        //       excludeLevel: [],
        //     },
        //     {
        //       name: "แพ้-ชนะ เกม",
        //       path: "/reports/game/by-date",
        //       level: 10,
        //       excludeLevel: [],
        //     },
        //   ],
        // },
        {
          name: "จัดการสมาชิก",
          icon: "fa fa-users",
          active: false,
          level: 9,
          excludeLevel: [31, 100],
          platformLevel: 1,
          subMenus: [
            {
              name: "เพิ่มสมาชิก",
              path: "/members/add",
              level: 9,
              excludeLevel: [],
              permission: "add_member_edit",
              platformLevel: 1,
            },
            {
              name: "ข้อมูลทั่วไปเอเย่นต์",
              path: "/members/edit/agent",
              level: 10,
              excludeLevel: [],
              permission: "edit_member_agent_view",
              platformLevel: 2,
            },
            {
              name: "ข้อมูลทั่วไปเมมเบอร์",
              path: "/members/edit/member",
              level: 9,
              excludeLevel: [],
              permission: "edit_member_member_view",
              platformLevel: 1,
            },
            {
              name: "แนะนำเพื่อน",
              path: "/members/affiliate",
              level: 9.1,
              excludeLevel: [],
              permission: "affiliate_view",
              platformLevel: 1,
            },
            // {
            //   name: "Warning Management",
            //   path: "/members/warning",
            //   level: 10,
            //   excludeLevel: [],
            // },
            {
              name: "จัดอันดับลูกค้า",
              path: "/members/ranking",
              level: 10,
              excludeLevel: [],
              permission: "member_ranking_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "เซียน",
          icon: "fa fa-crown",
          active: false,
          level: 10,
          excludeLevel: [31, 100],
          platformLevel: 1,
          subMenus: [
            {
              name: "จัดการ",
              path: "/members/sian",
              level: 10,
              excludeLevel: [],
              permission: "member_sian_view",
              platformLevel: 1,
            },
            {
              name: "รายงาน",
              path: "/members/sian/report",
              level: 10,
              excludeLevel: [],
              permission: "member_sian_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "การเงิน",
          icon: "fa fa-money-bill-wave",
          active: false,
          level: 9,
          excludeLevel: [31, 100],
          platformLevel: 1,
          subMenus: [
            {
              name: "Internal Transfer",
              path: "/internal-transfer",
              level: 10,
              excludeLevel: [],
              permission: "transfer_money_view",
              platformLevel: 1,
            },
            {
              name: "เติมเงินเอเย่นต์",
              path: "/credit/transfer/agent",
              level: 10,
              excludeLevel: [],
              permission: "credit_transfer_agent_view",
              platformLevel: 2,
            },
            {
              name: "เติมเงินเมมเบอร์",
              path: "/credit/transfer/member",
              level: 9.1,
              excludeLevel: [],
              permission: "credit_transfer_member_view",
              platformLevel: 1,
            },
            {
              name: "เงินเข้าออก (ธนาคาร)",
              path: "/credit/bank/statement",
              level: 9,
              excludeLevel: [],
              permission: "bank_statement_view",
              platformLevel: 1,
            },
            {
              name: "แจ้งฝาก (ลูกค้า)",
              path: "/credit/bank/transfer/request?code=DEPOSIT",
              level: 9,
              excludeLevel: [],
              permission: "bank_transfer_request_view",
              platformLevel: 1,
            },
            {
              name: "แจ้งถอน (ลูกค้า)",
              path: "/credit/bank/transfer/request?code=WITHDRAW",
              level: 9,
              excludeLevel: [],
              permission: "bank_transfer_request_view",
              platformLevel: 1,
            },
            {
              name: "ประวัติการเงิน",
              path: "/credit/transfer/history",
              level: 9,
              excludeLevel: [],
              permission: "credit_transfer_history_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "ธนาคาร",
          icon: "fas fa-university",
          active: false,
          level: 9,
          excludeLevel: [31],
          platformLevel: 1,
          subMenus: [
            {
              name: "บัญชีธนาคาร",
              path: "/bank/account",
              level: 9,
              excludeLevel: [],
              permission: "bank_account_view",
              platformLevel: 1,
            },
            {
              name: "แบนบัญชีธนาคาร",
              path: "/bank/ban",
              level: 10,
              excludeLevel: [],
              permission: "bank_ban_view",
              platformLevel: 1,
            },
            {
              name: "ตั้งค่าบัญชีธนาคาร",
              path: "/bank/setting",
              level: 32,
              excludeLevel: [],
              permission: "bank_setting_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "โปรโมชั่น",
          icon: "fas fa-bullhorn",
          active: false,
          level: 10,
          excludeLevel: [31],
          platformLevel: 1,
          subMenus: [
            {
              name: "จัดการ",
              path: "/promotion/detail",
              level: 10,
              excludeLevel: [],
              permission: "promotion_detail_view",
              platformLevel: 1,
            },
            {
              name: "รับโปรโมชั่น",
              path: "/promotion/user-promotion",
              level: 10,
              excludeLevel: [],
              permission: "user_promotion_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "ตั้งค่าแพลตฟอร์ม",
          icon: "fas fa-home",
          active: false,
          level: 10,
          excludeLevel: [31],
          platformLevel: 1,
          subMenus: [
            {
              name: "เปลี่ยนแพลตฟอร์ม",
              path: "/platform/change",
              level: 32,
              excludeLevel: [],
              permission: "platform_change_edit",
              platformLevel: 1,
            },
            {
              name: "deploy platform",
              path: "/platform/deploy",
              level: 99,
              excludeLevel: [],
              permission: "platform_deploy_edit",
              platformLevel: 1,
            },
            {
              name: "รายการแพลตฟอร์ม",
              path: "/platform",
              level: 10,
              excludeLevel: [],
              permission: "platform_list_view",
              platformLevel: 1,
            },
            {
              name: "เพิ่มแพลตฟอร์ม",
              path: "/platform/add",
              level: 99,
              excludeLevel: [],
              permission: "platform_add_edit",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "ตั้งค่าหวย",
          icon: "fas fa-cog",
          active: false,
          level: 10,
          excludeLevel: [31],
          platformLevel: 2,
          subMenus: [
            {
              name: "แบ่งหุ้น/เก็บของ",
              path: "/platform/share",
              level: 10,
              excludeLevel: [],
              permission: "edit_platform_type_share_view",
              platformLevel: 1,
            },
            {
              name: "ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข",
              path: "/platform/edit-category",
              level: 10,
              excludeLevel: [],
              permission: "edit_platform_category_view",
              platformLevel: 1,
            },
            {
              name: "อัตราจ่าย/ส่วนลด/af",
              path: "/platform/edit-pay",
              level: 10,
              excludeLevel: [],
              permission: "edit_pay_view",
              platformLevel: 1,
            },
            {
              name: "เปิด-ปิด หวย/อัตราจ่าย",
              path: "/platform/edit-openclose-lotto",
              level: 10,
              excludeLevel: [],
              permission: "edit_platform_type_pay_open_close_view",
              platformLevel: 1,
            },
            {
              name: "อัตราจ่ายตามหวย",
              path: "/platform/type-pay",
              level: 10,
              excludeLevel: [],
              permission: "platform_huay_type_pay_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "ตั้งค่าเกม",
          icon: "fas fa-cog",
          active: false,
          level: 10,
          excludeLevel: [31],
          platformLevel: 2,
          subMenus: [
            {
              name: "เปิด-ปิด เกม/ค่ายเกม",
              path: "/platform/edit-openclose-game",
              level: 10,
              excludeLevel: [],
              permission: "edit_platform_game_open_close_view",
              platformLevel: 1,
            },
            {
              name: "แบ่งหุ้น/เก็บของ เกม",
              path: "/platform/game-product-share",
              level: 10,
              excludeLevel: [],
              permission: "edit_platform_game_product_share_view",
              platformLevel: 1,
            },
            {
              name: "ตั้งค่า af",
              path: "/platform/game-af",
              level: 10,
              excludeLevel: [],
              permission: "platform_game_af_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "ตั้งค่าระบบหวย",
          icon: "fa fa-cog",
          active: false,
          level: 31,
          excludeLevel: [],
          platformLevel: 3,
          subMenus: [
            {
              name: "กลุ่มหวย",
              path: "/setting/lotto-group",
              level: 99,
              excludeLevel: [],
              permission: "lotto_group_list_view",
              platformLevel: 1,
            },
            {
              name: "ชนิดหวย",
              path: "/setting/lotto-type",
              level: 99,
              excludeLevel: [],
              permission: "lotto_type_list_view",
              platformLevel: 1,
            },
            {
              name: "งวดหวยตามวัน",
              path: "/setting/lotto-period-by-date",
              level: 31,
              excludeLevel: [],
              permission: "lotto_period_by_date_view",
              platformLevel: 1,
            },
            {
              name: "อัตราจ่าย",
              path: "/setting/pay-rate",
              level: 99,
              excludeLevel: [],
              permission: "pay_rate_list_view",
              platformLevel: 1,
            },
            {
              name: "ข่าวสาร",
              path: "/setting/news",
              level: 99,
              excludeLevel: [],
              permission: "setting_new_edit",
              platformLevel: 1,
            },
            {
              name: "ตั้งค่าระบบ",
              path: "/setting/system",
              level: 99,
              excludeLevel: [],
              permission: "system_view",
              platformLevel: 1,
            },
          ],
        },
        {
          name: "ตั้งค่าระบบเกม",
          icon: "fa fa-cog",
          active: false,
          level: 31,
          excludeLevel: [],
          platformLevel: 3,
          subMenus: [
            {
              name: "ค่ายเกม",
              path: "/game-setting/game-product",
              level: 99,
              excludeLevel: [],
              permission: "game_product_view",
              platformLevel: 1,
            },
            {
              name: "เกม",
              path: "/game-setting/game",
              level: 99,
              excludeLevel: [],
              permission: "game_view",
              platformLevel: 1,
            },
            {
              name: "ขั้นต่ำ/สูงสุด",
              path: "/game-setting/game-bet-limit",
              level: 99,
              excludeLevel: [],
              permission: "game_bet_limit_view",
              platformLevel: 1,
            },
          ],
        },
      ],
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout")
      this.$router.push("/login")
    },
    subMenu(subMenu) {
      return subMenu.filter(
        (item) =>
          this.currentUser.platformLevel >= item.platformLevel &&
          (!item.permission ||
            (this.currentUser.permissions &&
              this.currentUser.permissions.includes(item.permission)))
        // this.currentUser.levelNumber >= item.level &&
        // !item.excludeLevel.includes(this.currentUser.levelNumber)
      )
    },
  },
  computed: {
    menuItemsFilter() {
      return this.menuItems.filter(
        (item) =>
          (!item.permission ||
            (this.currentUser.platformLevel >= item.platformLevel &&
              this.currentUser.permissions &&
              this.currentUser.permissions.includes(item.permission))) &&
          (!Array.isArray(item.subMenus) ||
            this.subMenu(item.subMenus).length > 0)
        // this.currentUser.levelNumber >= item.level &&
        // this.currentUser.platformLevel >= item.platformLevel &&
        // !item.excludeLevel.includes(this.currentUser.levelNumber)
      )
    },
    currentUser() {
      return this.$store.state.auth.user.userData
    },
  },
  mounted() {
    this.$store.dispatch("auth/updateUserData")

    new PerfectScrollbar(".scrollbar", {
      wheelSpeed: 0.3,
    })

    this.menuItems.some((menuItem) => {
      let result = menuItem.subMenus.some((subMenu) => {
        return subMenu.path == this.$router.currentRoute.path
      })
      if (result) {
        menuItem.active = true
      }
    })

    $(".list-item").each(function () {
      let el = $(this)
      let linkHasClass = (className) => {
        return el.parent().find("a").hasClass(className)
      }
      if (linkHasClass("link-arrow")) {
        el.find("a").addClass("up")
        if (linkHasClass("link-current")) {
          let current = $(".link-current")
          current.addClass("active down")
          current.next("ul").show()
        }
      }
    })

    $(".link-arrow").on("click", function () {
      let el = $(this)
      el.addClass("transition").toggleClass("active rotate")

      !el.hasClass("link-current")
        ? el.addClass("link-current")
        : el.removeClass("link-current")

      el.next(".list-hidden").slideToggle("fast")
    })
  },
}
</script>
