import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
import Main from "@/layouts/Main.vue"
import Welcome from "@/views/Welcome.vue"
import Login from "@/views/Login.vue"
import PasswordExpire from "@/views/PasswordExpire.vue"
import Logout from "@/views/Logout.vue"
import Home from "@/views/Home"

// Edit member
import AddMember from "@/views/member/AddMember"
import EditMember from "@/views/member/EditMember"

// Credit
import CreditTransfer from "@/views/credit/CreditTransfer"
import CreditHistory from "@/views/credit/CreditHistory"
import CreditTransferHistory from "@/views/credit/CreditTransferHistory"
import CreditSummary from "@/views/credit/CreditSummary"
import BankTransferRequest from "@/views/credit/BankTransferRequest"
import BankStatement from "@/views/credit/BankStatement"

// Setting
import LottoAddMultiRound from "@/views/setting/LottoAddMultiRound"
import LottoGroup from "@/views/setting/LottoGroup"
import LottoGroupForm from "@/views/setting/LottoGroupForm"
import LottoType from "@/views/setting/LottoType"
import LottoTypeForm from "@/views/setting/LottoTypeForm"
import LottoRoundByDate from "@/views/setting/LottoRoundByDate"
import PayRate from "@/views/setting/PayRate"
import PayRateForm from "@/views/setting/PayRateForm"
import System from "@/views/setting/System"
import LottoRound from "@/views/setting/LottoRound"
import LottoAddResult from "@/views/setting/LottoAddResult"

// Game Setting
import GameProduct from "@/views/game_setting/GameProduct"
import Game from "@/views/game_setting/Game"
import GameBetLimit from "@/views/game_setting/GameBetLimit"

// Report
import ReportByDate from "@/views/report/ReportByDate"
import ReportByRound from "@/views/report/ReportByRound"
import ReportByItem from "@/views/report/ReportByItem"

// Item
import ItemCancelled from "@/views/item/ItemCancelled"
import ItemHalfPayout from "@/views/item/ItemHalfPayout"
import ItemLimitSetting from "@/views/item/ItemLimitSetting"
import ItemOverall from "@/views/item/ItemOverall"
import ItemSetting from "@/views/item/ItemSetting"

// Profile
import ProfileSetting from "@/views/profile/Setting"

import Dashboard from "@/views/Dashboard"
import ResultSian from "@/views/ResultSian"
import Graph from "@/views/Graph"

// Platform
import PlatformList from "@/views/platform/PlatformList"
import AddPlatform from "@/views/platform/AddPlatform"
import EditPlatformTypeShare from "@/views/platform/EditPlatformTypeShare"
import EditPlatformCategory from "@/views/platform/EditPlatformCategory"
import EditPlatformPay from "@/views/platform/EditPlatformPay"
import EditPlatformTypePayOpenClose from "@/views/platform/EditPlatformTypePayOpenClose"
import PlatformHuayTypePay from "@/views/platform/PlatformHuayTypePay"

// Platform Game
import EditPlatformGameOpenClose from "@/views/platform_game/EditPlatformGameOpenClose"
import EditPlatformGameProductShare from "@/views/platform_game/EditPlatformGameProductShare"
import PlatformGameAF from "@/views/platform_game/PlatformGameAF"

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/password-expire",
    name: "PasswordExpire",
    component: PasswordExpire,
  },
  {
    path: "/result-sian/:userId",
    name: "ResultSian",
    component: ResultSian,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "*",
    name: "Root",
    component: Main,
    redirect: "/welcome",
    children: [
      {
        path: "/internal-transfer",
        name: "InternalTransfer",
        component: () => import("@/views/credit/InternalTransfer.vue"),
        meta: {
          permission: "transfer_money_view",
        },
      },
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/welcome",
        name: "Welcome",
        component: Welcome,
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/graph",
        name: "Graph",
        component: Graph,
      },
      {
        path: "/credit",
        name: "Credit",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "transfer/agent",
            name: "CreditTransferAgent",
            component: CreditTransfer,
            meta: {
              permission: "credit_transfer_agent_view",
            },
          },
          {
            path: "transfer/member",
            name: "CreditTransferMember",
            component: CreditTransfer,
            meta: {
              permission: "credit_transfer_member_view",
            },
          },
          {
            path: "history",
            name: "CreditHistory",
            component: CreditHistory,
          },
          {
            path: "transfer/history",
            name: "CreditTransferHistory",
            component: CreditTransferHistory,
            meta: {
              permission: "credit_transfer_history_view",
            },
          },
          {
            path: "bank/transfer/request",
            name: "BankTransferRequest",
            component: BankTransferRequest,
            meta: {
              permission: "bank_transfer_request_view",
            },
          },
          {
            path: "bank/transfer/request/add",
            name: "AddBankTransferRequest",
            component: () => import("@/views/credit/AddBankTransferRequest"),
          },
          {
            path: "bank/statement",
            name: "BankStatement",
            component: BankStatement,
            meta: {
              permission: "bank_statement_view",
            },
          },
          {
            path: "summary",
            name: "CreditSummary",
            component: CreditSummary,
          },
        ],
      },
      {
        path: "/bank",
        name: "Bank",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "account",
            name: "BankAccount",
            component: () => import("@/views/bank/BankAccount"),
            meta: {
              permission: "bank_account_view",
            },
          },
          {
            path: "account/add",
            name: "BankAccountAdd",
            component: () => import("@/views/bank/BankAccountAdd"),
          },
          {
            path: "ban",
            name: "BankBan",
            component: () => import("@/views/bank/BankBan"),
            meta: {
              permission: "bank_ban_view",
            },
          },
          {
            path: "setting",
            name: "BankSetting",
            component: () => import("@/views/bank/BankSetting"),
            meta: {
              permission: "bank_setting_view",
            },
          },
        ],
      },
      {
        path: "/members",
        name: "ManageMember",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "add",
            name: "AddMember",
            component: AddMember,
            meta: {
              permission: "add_member_edit",
            },
          },
          {
            path: "edit/agent",
            name: "EditMemberAgent",
            component: EditMember,
            meta: {
              permission: "edit_member_agent_view",
            },
          },
          {
            path: "edit/member",
            name: "EditMemberMember",
            component: EditMember,
            meta: {
              permission: "edit_member_member_view",
            },
          },
          {
            path: "edit/:userId",
            name: "EditMemberByUserId",
            component: EditMember,
          },
          {
            path: "affiliate",
            name: "Affiliate",
            component: () => import("@/views/member/Affiliate"),
            meta: {
              permission: "affiliate_view",
            },
          },
          {
            path: "affiliate/statement/:userId",
            name: "AffiliateStatement",
            component: () => import("@/views/member/AffiliateStatement"),
          },
          {
            path: "warning",
            name: "MemberWarning",
            component: () => import("@/views/member/Warning"),
          },
          {
            path: "ranking",
            name: "MemberRanking",
            component: () => import("@/views/member/Ranking"),
            meta: {
              permission: "member_ranking_view",
            },
          },
          {
            path: "sian",
            name: "SianManage",
            component: () => import("@/views/sian/Manage"),
            meta: {
              permission: "member_sian_view",
            },
          },
          {
            path: "sian/report",
            name: "SianReport",
            component: () => import("@/views/sian/Report"),
            meta: {
              permission: "member_sian_view",
            },
          },
          {
            path: ":userId",
            name: "MemberDetail",
            component: () => import("@/views/member/MemberDetail"),
          },
        ],
      },
      {
        path: "/setting",
        name: "Setting",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "news",
            name: "SettingNews",
            component: () => import("@/views/setting/News.vue"),
            meta: {
              permission: "setting_new_edit",
            },
          },
          {
            path: "lotto-group",
            component: {
              render(c) {
                return c("router-view")
              },
            },
            children: [
              {
                path: "",
                name: "Lotto Group List",
                component: LottoGroup,
                meta: {
                  permission: "lotto_group_list_view",
                },
              },
              {
                path: "add",
                name: "Lotto Group Add",
                component: LottoGroupForm,
              },
              {
                path: "edit",
                name: "Lotto Group Edit",
                component: LottoGroupForm,
              },
            ],
          },
          {
            path: "lotto-type",
            component: {
              render(c) {
                return c("router-view")
              },
            },
            children: [
              {
                path: "",
                name: "Lotto Type List",
                component: LottoType,
                meta: {
                  permission: "lotto_type_list_view",
                },
              },
              {
                path: "add",
                name: "Lotto Type Add",
                component: LottoTypeForm,
              },
              {
                path: "edit",
                name: "Lotto Type Edit",
                component: LottoTypeForm,
              },
            ],
          },
          {
            path: "lotto-period-by-date",
            name: "Lotto Period By Date",
            component: LottoRoundByDate,
            meta: {
              permission: "lotto_period_by_date_view",
            },
          },
          {
            path: "pay-rate",
            component: {
              render(c) {
                return c("router-view")
              },
            },
            children: [
              {
                path: "",
                name: "Pay Rate List",
                component: PayRate,
                meta: {
                  permission: "pay_rate_list_view",
                },
              },
              {
                path: "add",
                name: "Pay Rate Add",
                component: PayRateForm,
              },
              {
                path: "edit",
                name: "Pay Rate Edit",
                component: PayRateForm,
              },
            ],
          },
          {
            path: "lotto-add-multi-round",
            name: "LottoAddMultiRound",
            component: LottoAddMultiRound,
          },
          {
            path: "system",
            name: "System",
            component: System,
            meta: {
              permission: "system_view",
            },
          },
          {
            path: "lotto-round/:id",
            name: "LottoRoundUpdate",
            component: LottoRound,
          },
          {
            path: "lotto-round",
            name: "LottoRoundCreate",
            component: LottoRound,
          },
          {
            path: "lotto-result/add/:id",
            name: "LottoAddResult",
            component: LottoAddResult,
          },
        ],
      },
      {
        path: "/game-setting",
        name: "Game Setting",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "game-product",
            name: "GameProduct",
            component: GameProduct,
            meta: {
              permission: "game_product_view",
            },
          },
          {
            path: "game",
            name: "Game",
            component: Game,
            meta: {
              permission: "game_view",
            },
          },
          {
            path: "game-bet-limit",
            name: "GameBetLimit",
            component: GameBetLimit,
            meta: {
              permission: "game_bet_limit_view",
            },
          },
        ],
      },
      {
        path: "/reports",
        name: "Report",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "game-product/by-date",
            name: "ReportGameProductByDate",
            component: () =>
              import("@/views/report/ReportGameProductByDate.vue"),
          },
          {
            path: "game-product/by-date/:platformId",
            name: "ReportGameProductPlatformByDate",
            component: () =>
              import("@/views/report/ReportGameProductByDate.vue"),
          },
          {
            path: "game/by-date",
            name: "ReportGameByDate",
            component: () => import("@/views/report/ReportGameByDate.vue"),
          },
          {
            path: "game/by-date/:platformId",
            name: "ReportGamePlatformByDate",
            component: () => import("@/views/report/ReportGameByDate.vue"),
          },
          {
            path: "game-result",
            name: "ReportGameResult",
            component: () => import("@/views/report/ReportGameResult.vue"),
            meta: {
              permission: "report_game_result_view",
            },
          },
          {
            path: "by-date",
            name: "ReportByDate",
            component: ReportByDate,
            meta: {
              permission: "report_by_date_view",
            },
          },
          {
            path: "by-date/:platformId",
            name: "ReportUserByDate",
            component: ReportByDate,
          },
          {
            path: "by-date/of-member/:userId",
            name: "ReportByDateOfMember",
            component: ReportByItem,
          },
          {
            path: "by-member",
            name: "ReportByMember",
            component: ReportByRound,
            meta: {
              permission: "report_by_member_view",
            },
          },
          {
            path: "by-member/:platformId",
            name: "ReportUserByMember",
            component: ReportByRound,
          },
          {
            path: "by-member/of-number/:userId",
            name: "ReportByMemberOfNumber",
            component: ReportByItem,
          },
          {
            path: "winning",
            name: "ReportWinning",
            component: ReportByItem,
          },
        ],
      },
      {
        path: "/items",
        name: "Item",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "by-date",
            name: "ReportWaitingByDate",
            component: ReportByDate,
            meta: {
              permission: "report_waiting_by_date_view",
            },
          },
          {
            path: "by-date/:platformId",
            name: "ReportWaitingUserByDate",
            component: ReportByDate,
          },
          {
            path: "by-round/:platformId",
            name: "ItemUserByRound",
            component: ReportByRound,
          },
          {
            path: "by-round",
            name: "ItemByRound",
            component: ReportByRound,
            meta: {
              permission: "item_by_round_view",
            },
          },
          {
            path: "cancelled",
            name: "ItemCancelled",
            component: ItemCancelled,
          },
          {
            path: "half-payouts",
            name: "ItemHalfPayout",
            component: ItemHalfPayout,
            meta: {
              permission: "item_half_payout_view",
            },
          },
          {
            path: "limit-settings",
            name: "ItemLimitSetting",
            component: ItemLimitSetting,
            meta: {
              permission: "item_limit_setting_view",
            },
          },
          {
            path: "overall",
            name: "ItemOverall",
            component: ItemOverall,
            meta: {
              permission: "item_overall_view",
            },
          },
          {
            path: "overall/:userId",
            name: "ItemOverallUser",
            component: ItemOverall,
          },
          {
            path: "of-number/:categoryId/:number",
            name: "ItemOfNumber",
            component: ReportByItem,
          },
          {
            path: "by-date/of-member/:userId",
            name: "ItemByDateOfMember",
            component: ReportByItem,
          },
          {
            path: "of-number/:userId",
            name: "ItemOfMember",
            component: ReportByItem,
          },
          {
            path: "settings",
            name: "ItemSetting",
            component: ItemSetting,
            meta: {
              permission: "item_setting_view",
            },
          },
        ],
      },
      {
        path: "profile/setting",
        name: "Profile Setting",
        component: ProfileSetting,
      },
      {
        path: "/platform",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "/",
            name: "PlatformList",
            component: PlatformList,
            meta: {
              permission: "platform_list_view",
            },
          },
          {
            path: "change",
            name: "PlatformChange",
            component: () => import("@/views/platform/PlatformChange.vue"),
            meta: {
              permission: "platform_change_edit",
            },
          },
          {
            path: "deploy",
            name: "PlatformDeploy",
            component: () => import("@/views/platform/PlatformDeploy.vue"),
            meta: {
              permission: "platform_deploy_edit",
            },
          },
          {
            path: ":platformId/list",
            name: "PlatformListByPlatformID",
            component: PlatformList,
          },
          {
            path: "add",
            name: "PlatformAdd",
            component: AddPlatform,
            meta: {
              permission: "platform_add_edit",
            },
          },
          {
            path: "share",
            name: "EditPlatformTypeShare",
            component: EditPlatformTypeShare,
            meta: {
              permission: "edit_platform_type_share_view",
            },
          },
          {
            path: "share/:platformId",
            name: "EditPlatformTypeShareByPlatformId",
            component: EditPlatformTypeShare,
          },
          {
            path: "edit-category",
            name: "EditPlatformCategory",
            component: EditPlatformCategory,
            meta: {
              permission: "edit_platform_category_view",
            },
          },
          {
            path: "edit-category/:platformId",
            name: "EditPlatformCategoryByPlatformId",
            component: EditPlatformCategory,
          },
          {
            path: "edit-pay",
            name: "EditPlatformPay",
            component: EditPlatformPay,
            meta: {
              permission: "edit_pay_view",
            },
          },
          {
            path: "edit-pay/:platformId",
            name: "EditPlatformPayByPlatformId",
            component: EditPlatformPay,
          },
          {
            path: "edit-openclose-lotto",
            name: "EditPlatformTypePayOpenClose",
            component: EditPlatformTypePayOpenClose,
            meta: {
              permission: "edit_platform_type_pay_open_close_view",
            },
          },
          {
            path: "edit-openclose-lotto/:platformId",
            name: "EditPlatformTypePayOpenCloseByPlatformId",
            component: EditPlatformTypePayOpenClose,
          },
          {
            path: "type-pay",
            name: "PlatformHuayTypePay",
            component: PlatformHuayTypePay,
            meta: {
              permission: "platform_huay_type_pay_view",
            },
          },
          {
            path: "type-pay/:platformId",
            name: "PlatformHuayTypePayByPlatformId",
            component: PlatformHuayTypePay,
          },
          {
            path: "edit-openclose-game",
            name: "EditPlatformGameOpenClose",
            component: EditPlatformGameOpenClose,
            meta: {
              permission: "edit_platform_game_open_close_view",
            },
          },
          {
            path: "edit-openclose-game/:platformId",
            name: "EditPlatformGameOpenCloseByPlatformId",
            component: EditPlatformGameOpenClose,
          },
          {
            path: "game-product-share",
            name: "EditPlatformGameProductShare",
            component: EditPlatformGameProductShare,
            meta: {
              permission: "edit_platform_game_product_share_view",
            },
          },
          {
            path: "game-product-share/:platformId",
            name: "EditPlatformGameProductShareByPlatformId",
            component: EditPlatformGameProductShare,
          },
          {
            path: "game-af",
            name: "PlatformGameAF",
            component: PlatformGameAF,
            meta: {
              permission: "platform_game_af_view",
            },
          },
          {
            path: "game-af/:platformId",
            name: "PlatformGameAFByPlatformId",
            component: PlatformGameAF,
          },
        ],
      },
      {
        path: "/promotion",
        name: "Promotion",
        component: {
          render(c) {
            return c("router-view")
          },
        },
        children: [
          {
            path: "detail",
            name: "PromotionDetail",
            component: () => import("@/views/promotion/PromotionDetail.vue"),
            meta: {
              permission: "promotion_detail_view",
            },
          },
          {
            path: "user-promotion",
            name: "UserPromotion",
            component: () => import("@/views/promotion/UserPromotion.vue"),
            meta: {
              permission: "user_promotion_view",
            },
          },
        ],
      },
    ],
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login", "/password-expire", "/result-sian"]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem("user")

  console.log(to.name)
  if (to.name == "ResultSian") {
    return next()
  }

  if (authRequired && !loggedIn) {
    return next("/login")
  }

  if (to.meta.permission) {
    if (!loggedIn) {
      return next("/login")
    }
    if (
      !store.state.auth.user?.userData?.permissions?.includes(
        to.meta.permission
      )
    ) {
      next("/welcome")
    }
  }

  next()
})

export default router
