<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <button class="btn btn-primary rounded-0" @click="reload" :disabled="disabledReload">
        <i class="fas fa-redo"></i>
      </button>

      <b-dropdown :text="$store.state.auth.typeName" class="w-100">
        <b-dropdown-group :header="item.name" v-for="(item, index) in huayGroups" :key="index">
          <b-dropdown-item-button v-for="(huayType, index2) in filterHuayTypes(item)" :key="index2"
            :active="typeActive.id == huayType.id" @click="setType(huayType, true)">
            {{ huayType.name }}
          </b-dropdown-item-button>
        </b-dropdown-group>
      </b-dropdown>
    </div>

    <b-dropdown v-if="typeActive.multiRoundPerDay" variant="info" :text="dateYeekee.name">
      <b-dropdown-item v-for="(item, index) in dateDropdown" :key="index" @click="dateYeekee = item">{{ item.name
      }}</b-dropdown-item>
    </b-dropdown>
    <b-dropdown v-if="typeActive.multiRoundPerDay" variant="success" :text="roundYeekee.name">
      <b-dropdown-item v-for="(item, index) in roundDropdown" :key="index" @click="setRoundHuayTypeMultiPerDay(item)">{{
        item.name }}</b-dropdown-item>
    </b-dropdown>
    <b-dropdown v-if="!typeActive.multiRoundPerDay" variant="success" :text="$store.state.auth.roundName">
      <b-dropdown-item v-for="(item, index) in filterHuayRounds()" :key="index" :active="roundActive.id == item.id"
        @click="setRound(item, true)">
        {{ item.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style>
.dropdown-header {
  background-color: #28681b;
  color: white !important;
}

.dropdown-toggle {
  border-radius: 0 !important;
}

.dropdown-menu.show {
  border-radius: 0 !important;
}

.dropdown-item {
  font-size: 0.75rem !important;
}

.bg-nav-top {
  background: linear-gradient(90deg,
      rgba(75, 58, 36, 1) 0%,
      rgba(117, 94, 58, 1) 29%,
      rgba(183, 150, 113, 1) 52%,
      rgba(240, 216, 170, 1) 69%,
      rgba(117, 94, 58, 1) 90%);
}

.bg-nav-top-rockett888 {
  background: linear-gradient(-5deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(27, 35, 44, 1) 56%);
}

a.navbar-brand {
  color: white;
  font-size: 1.4rem;
}

.navbar-toggler-icon {
  color: white;
}

.navbar {
  padding: 0;
}
</style>

<script>
export default {
  name: "Header",
  data() {
    return {
      // currentTime: "",
      roundActive: {},
      typeActive: {},
      huayGroups: [],
      huayTypes: [],
      huayRounds: [],
      disabledReload: false,
      timeout: null,
      dateDropdown: [],
      roundDropdown: [],
      dateYeekee: {},
      roundYeekee: {},
    }
  },
  computed: {
    isWebRockett888() {
      return process.env.VUE_APP_WEB_NAME === "rockett888"
    },
  },
  methods: {
    async setRoundHuayTypeMultiPerDay(item) {
      this.roundYeekee = item

      this.swal.processing()

      try {
        let res = await this.axios({
          method: "get",
          url: "/huay/round/yeekee",
          params: {
            huayTypeId: this.typeActive.id,
            roundNo: item.roundNo,
            closeDate: this.dateYeekee.date.format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        })

        this.setRound(res.data.data, true)

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    setRoundId() {
      this.$store.state.auth.roundName = "#" + this.$store.state.auth.roundId
    },
    reload() {
      this.disabledReload = true

      this.timeout = setTimeout(() => {
        this.disabledReload = false
      }, 3000)

      this.$store.dispatch("auth/forceReload")
    },
    filterHuayRounds() {
      return this.huayRounds.filter(
        (item) => item.huayTypeId == this.typeActive.id
      )
    },
    setRound(item, reload) {
      this.roundActive = item
      this.$store.state.auth.roundId = item.id
      this.$store.state.auth.roundName = item.name
      this.$store.state.auth.roundDate = this.$date(item.closeDate).format(
        "DD-MM-YYYY"
      )

      if (reload) {
        this.$store.dispatch("auth/forceReload")
      }
    },
    setType(item, reload) {
      this.typeActive = item
      this.$store.state.auth.typeId = item.id
      this.$store.state.auth.typeName = item.name

      if (item.multiRoundPerDay) {
        return
      }

      let firstRound = this.huayRounds.find(
        (item) => item.huayTypeId == this.typeActive.id
      )
      let huayRoundBetween = this.huayRounds.find(
        (item) =>
          item.huayTypeId == this.typeActive.id &&
          this.$date().isBetween(
            this.$date(item.openTime),
            this.$date(item.closeTime)
          )
      )
      let huayRound = this.huayRounds.find(
        (item) =>
          item.huayTypeId == this.typeActive.id &&
          this.$date().format("YYYY-MM-DD") ==
          this.$date(item.closeTime).format("YYYY-MM-DD")
      )
      if (huayRoundBetween) {
        this.setRound(huayRoundBetween, reload)
      } else if (huayRound) {
        this.setRound(huayRound, reload)
      } else if (firstRound) {
        this.setRound(firstRound, reload)
      } else {
        this.setRound({}, false)
      }
    },
    filterHuayTypes(item) {
      return this.huayTypes.filter((item2) => item2.huayGroupId == item.id)
    },
    // makeCurrentTime() {
    //   let date = this.$date()
    //   this.currentTime = `วันที่ ${date.format("DD")} ${date.format("MMMM")} ${parseInt(date.format("YYYY")) + 543
    //     } ${date.format("HH:mm:ss")}`
    // },
  },
  beforeCreate: function () {
    document.body.className = ""
  },
  async mounted() {
    // this.makeCurrentTime()

    // setInterval(() => {
    //   this.makeCurrentTime()
    // }, 1000)

    this.swal.processing()

    try {
      let res = await this.axios({
        method: "get",
        url: "/huay/round/header",
      })
      this.swal.close()

      let data = res.data.data
      this.huayGroups = data.huayGroups
      this.huayTypes = data.huayTypes

      if (this.huayTypes.length == 0) {
        return
      }

      this.huayRounds = data.huayRounds

      const huayType = this.huayTypes.find((item) => !item.multiRoundPerDay)

      this.setType(huayType, false)

      let huayRound = this.huayRounds.find(
        (item) =>
          this.$date(item.closeDate).format("DD-MM-YYYY") ==
          this.$date().format("DD-MM-YYYY") && item.huayTypeId == huayType.id
      )

      if (!huayRound) {
        huayRound = this.huayRounds.find(
          (item) => item.huayTypeId == huayType.id
        )
      }

      this.setRound(huayRound, false)

      for (let i = 1; i >= -30; i--) {
        const d = this.$date().add(i, "day")
        this.dateDropdown.push({
          name: "วันที่ " + d.format("DD/MM/YYYY"),
          date: d,
        })
      }

      this.dateYeekee = this.dateDropdown[1]

      for (let i = 1; i <= 96; i++) {
        this.roundDropdown.push({
          name: "รอบที่ " + i,
          roundNo: i,
        })
      }

      this.roundYeekee = this.roundDropdown[0]
    } catch (e) {
      console.log(e)
      this.swal.swalError()
    }
  },
}
</script>